/* @ts-nocheck */
/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  eurorateiben: 1,
  ideuro: "",
};

export const rateEuroibenSlice = createSlice({
  name: "eurorateiben",
  initialState,
  reducers: {
    getEuroRate: (state, action) => {
      state.eurorateiben = action.payload.euro;
      state.ideuro = action.payload.ideuro;
      state.loading = false;
    },

    updateEuro: (state, action) => {
      state.eurorateiben = action.payload.euro;
    },
  },
});

export const { getEuroRate, updateEuro } = rateEuroibenSlice.actions;

export default rateEuroibenSlice.reducer;
