/* @ts-nocheck */
/* eslint-disable */

import React from "react";

const BackdropIben = ({ showPopupiben, closePopupiben }) => {
  return (
    showPopupiben && (
      <div
        className="backdropiben"
        onClick={() => closePopupiben("", "")}
      ></div>
    )
  );
};

export default BackdropIben;
