/* @ts-nocheck */
/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Header } from "../components";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RiEyeFill, RiAddBoxFill, RiDeleteBin6Line } from "react-icons/ri";

import { useDispatch } from "react-redux";

import { deleteSingleUser } from "../features/ibendouma/useribenSlices";

import { getAllUsers } from "../features/ibendouma/useribenSlices";

// import defaultUser from "../assets/default-user.png";

import { BiSearch } from "react-icons/bi";

import { Link } from "react-router-dom";

import axios from "axios";

import profilUser from "../assets/default-user.png";

const IbendoumaCustomer = ({ showPopupiben, closePopupiben, infoiben }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { usersiben } = useSelector((state) => state.useriben);

  const [searchTerm, setSearchTerm] = useState("");

  const notifySuccessToDeleteUser = (first, second) =>
    toast.success(
      "l'utilisateur " + first + " " + second + " à été supprimé avec succés"
    );

  // const handleAddNewDofusOrderUser = (idToAddOrder) => {
  //   console.log(idToAddOrder);
  // };
  
  const convertDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return dateConverted;
  };

  const handleRemoveUser = (idUserToRemove) => {
    try {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/users/${idUserToRemove}`,
      }).then((res) => {
        dispatch(deleteSingleUser({ id: res?.data._id }));
        notifySuccessToDeleteUser(res?.data?.lastname, res?.data?.firstname);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg customeriben">
      <div className="flex items-center justify-around mr-6">
        <Header category="Page" title="Customers" />
        <div className="infos-user-search">
          <input
            type="text"
            name="search"
            id="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search user..."
            className="xl:w-[500px] w-full"
          />
          <span className="absolute text-slate-400 text-3xl left-[82%] xl:left-[92%] top-[50%] -translate-y-[50%]">
            <BiSearch />
          </span>
        </div>
      </div>
      <div className="mt-2 mb-2 table_responsive">
        <table>
          <thead>
            <tr>
              <th>Num</th>
              {/* <th>Profil</th> */}
              <th>Lastname</th>
              <th>Firstname</th>
              {/* <th>Address</th> */}
              <th>Email</th>
              {/* <th>Pays</th> */}
              {/* <th>Ville</th>
              <th>phone</th> */}
              {/* <th>Devise</th> */}
              {/* <th>Methode de paiement</th> */}
              <th>Member since</th>
              <th
                style={{
                  display:
                    user?.person?.email === "test@ibendoumatest.com"
                      ? "none"
                      : "flex",
                }}
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {usersiben
              ?.filter((user) =>
                user.email.includes(searchTerm.toLocaleLowerCase())
              )
              ?.filter((user) => !user?.isAdmin)
              ?.sort((a, b) => a.createdAt - b.createdAt)
              ?.map((singleuser, i) => (
                <tr key={singleuser?._id}>
                  <td>
                    {i >= 9 ? "" : "0"}
                    {i + 1}
                  </td>
                  {/*<td>
                    <img
                      src={
                        singleuser?.profil ? singleuser?.profil : defaultUser
                      }
                      alt="profil"
                    />
                  </td> */}
                  <td>{singleuser?.lastname}</td>
                  <td>{singleuser?.firstname}</td>
                  {/* <td>{singleuser?.address}</td> */}
                  <td>{singleuser?.email}</td>
                  {/* <td>{singleuser?.country}</td> */}
                  {/* <td>{singleuser?.city}</td>
                  <td>{singleuser?.phone}</td> */}
                  {/* <td>{singleuser?.currency}</td>
                  <td>{singleuser?.currencymethod}</td> */}
                  <td>{convertDate(singleuser?.createdAt)}</td>
                  <td
                    style={{
                      width: "70px",
                      display:
                        user?.person?.email === "test@ibendoumatest.com"
                          ? "none"
                          : "flex",
                    }}
                  >
                    <div className="action_btn">
                      <div className="profil-detail-user">
                        <span
                          className="servers-view"
                          onClick={() => closePopupiben(singleuser?._id, true)}
                        >
                          <RiEyeFill />
                        </span>
                      </div>
                      <div className="profil-detail-user">
                        <span
                          className="servers-delete"
                          onClick={() => handleRemoveUser(singleuser?._id)}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </div>
                      {/* <Link
                        to={`/profil/${singleuser?._id}`}
                        className="profil-detail-user"
                      >
                        <span className="servers-add">
                          <RiAddBoxFill />
                        </span>
                      </Link> */}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="popup-container">
        {showPopupiben && (
          <div className="popup">
            <div className="my-infos">
              <div className="my-infos-user-profil">
                <img
                  src={infoiben?.profil ? infoiben?.profil : profilUser}
                  alt="profil user"
                />

                <label htmlFor="">Prénom: {infoiben?.lastname}</label>
                <label htmlFor="">Nom: {infoiben?.firstname}</label>
                <label htmlFor="">Email: {infoiben?.email}</label>
                <label htmlFor="">Adresse: {infoiben?.address}</label>
                <label htmlFor="">Téléphone: {infoiben?.phone}</label>
              </div>
              <div className="my-infos-labels">
                <div className="infos-payment">
                  <label htmlFor="">Pays: {infoiben?.country}</label>
                  <label htmlFor="">Ville: {infoiben?.city}</label>
                  <label htmlFor="">Devise: Pas Implémenté</label>
                </div>
                {infoiben?.currency === "dhs" && (
                  <div className="infos-payment">
                    <div className="infos-payment-fields">
                      <label htmlFor="">
                        Methode de paiements: {infoiben?.currencymethod}
                      </label>
                      <label htmlFor="">Banque: {infoiben?.dhsBank}</label>
                      <label htmlFor="">
                        Prénom: {infoiben?.dhsBankLastname}
                      </label>
                      <label htmlFor="">
                        Nom: {infoiben?.dhsBankFirstname}
                      </label>
                      <label htmlFor="">RIB: {infoiben?.dhsRib}</label>
                    </div>
                  </div>
                )}

                {infoiben?.currency === "euro" && (
                  <div className="infos-payment">
                    <div className="infos-payment-fields">
                      <label htmlFor="">
                        Methode de paiements: {infoiben?.currencymethod}
                      </label>
                      {infoiben?.currencymethod === "skrill" && (
                        <label htmlFor="">
                          Email de paimement : {infoiben?.emailCurrencyEuro}
                        </label>
                      )}

                      {infoiben?.currencymethod === "payeer" && (
                        <label htmlFor="">
                          Compte Payeer : {infoiben?.payeeraccount}
                        </label>
                      )}

                      {infoiben?.currencymethod === "paypal" && (
                        <label htmlFor="">
                          Email de paimement : {infoiben?.emailCurrencyEuro}
                        </label>
                      )}
                      {infoiben?.currencymethod === "sepa" && (
                        <label htmlFor="">IBAN: {infoiben?.ibanCurrency}</label>
                      )}

                      {infoiben?.currencymethod === "paylib" && (
                        <div className="modify-profil-sepa">
                          <label htmlFor="">
                            Prénom: {infoiben?.paylibcurencyLastname}
                          </label>
                          <label htmlFor="">
                            Nom: {infoiben?.paylibcurencyFirstname}
                          </label>
                          <label htmlFor="">
                            Téléphone: {infoiben?.paylibcurencyTel}
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {infoiben?.currency === "usdt" && (
                  <div className="infos-payment">
                    <div className="infos-payment-fields">
                      <label htmlFor="">
                        Methode de paiements: {infoiben?.currencymethod}
                      </label>
                      {infoiben?.currencymethod === "binance pay" && (
                        <label htmlFor="">
                          Email de paimement: {infoiben?.emailCurrencyEuro}
                        </label>
                      )}
                      {infoiben?.currencymethod === "trc20" && (
                        <label htmlFor="">
                          Adresse TRX: {infoiben?.usdtAdressTrx}
                        </label>
                      )}
                    </div>
                  </div>
                )}

                {infoiben?.currency === "cny" && (
                  <div className="infos-payment">
                    <div className="infos-payment-fields">
                      <label htmlFor="">
                        Methode de paiements: {infoiben?.currencymethod}
                      </label>
                      {infoiben?.currencymethod === "alipay" && (
                        <label htmlFor="">
                          Compte alipay : {infoiben?.emailCurrencyEuro}
                        </label>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default IbendoumaCustomer;
