/* @ts-nocheck */
/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  soldesiben: null,
};

export const dataSoldeibenSlice = createSlice({
  name: "soldesiben",
  initialState,
  reducers: {
    getSoldeHistory: (state, action) => {
      state.soldesiben = action.payload;
      state.loading = false;
    },

    updateHistorySolde: (state, action) => {
      state.soldesiben = state.soldesiben.map((solde) => {
        if (solde?._id === action.payload?._id) {
          return {
            ...solde,
            priceDh: action.payload.priceDh,
            status: action.payload.status,
          };
        } else return solde;
      });
    },

    deleteSoldeHistory: (state, action) => {
      state.soldesiben = state.soldesiben.filter(
        (solde) => solde?._id !== action?.payload?.id
      );
    },

    addNewSolde: (state, action) => {
      state.soldesiben.push(action.payload);
    },
  },
});

export const {
  getSoldeHistory,
  updateHistorySolde,
  deleteSoldeHistory,
  addNewSolde,
} = dataSoldeibenSlice.actions;

export default dataSoldeibenSlice.reducer;
