/* @ts-nocheck */
/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { addNewOrder } from "../features/ordersSlice";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

const AddNewDofusOrder = ({ isViewProduct, isViewUserId }) => {
  const dispatch = useDispatch();

  const { servers } = useSelector((state) => state.servers);

  const [userInfo, setUserInfo] = useState(null);

  const [userId, setUserId] = useState("");
  const [numBuy, setNumBuy] = useState("");
  const [jeu, setJeu] = useState("");
  const [server, setServer] = useState("");
  const [pu, setPu] = useState(1);
  const [qte, setQte] = useState(1);
  const [totalPrice, setTotalPrice] = useState("");
  // const [paymentMethod, setPaymentMethod] = useState("");
  const [status, setStatus] = useState("");
  const [gameName, setGameName] = useState("");
  const [serverFiltered, setServerFiltered] = useState(servers);
  const [infoDetails, setInfoDetails] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const notifyErrorToAddSoldeUser = () =>
    toast.error(
      "Veuiller remplir tous les champs avant de confirmer la commande"
    );

  const notifySuccessToAddOrder = (name) =>
    toast.success("Commande N° " + name + " à été ajouté avec succés");

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/users/${isViewUserId}`,
      }).then((res) => {
        setUserInfo(res?.data);
        setUserId(res?.data?._id);
      });
    } catch (error) {
      console.log(error);
    }
  }, [isViewUserId]);

  const handleModifyServer = (e) => {
    setServer(e.target.value);
    let priceServer = servers.filter(
      (server) => server.serverName === e.target.value
    )[0].serverPriceDh;
    setPu(priceServer);
  };

  useEffect(() => {
    setTotalPrice((qte * pu).toFixed(2));
  }, [qte, pu]);

  const codeGenerated = () => {
    const generateRandomCode =
      "0123456789abcdefghijklmnopkrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let myCode = "";
    for (let i = 0; i < 7; i++) {
      let code = Math.floor(Math.random() * generateRandomCode.length);
      myCode += generateRandomCode[code];
    }
    return myCode;
  };

  useEffect(() => {
    setNumBuy(codeGenerated());
  }, []);

  const handleModifJeu = (e) => {
    setJeu(e.target.value);

    setServerFiltered(
      servers.filter((server) => server?.serverCategory === e.target.value)
    );
  };

  const handleAddOrder = (e) => {
    e.preventDefault();
    if (
      !userId ||
      !jeu ||
      !qte ||
      !pu ||
      !gameName ||
      !paymentMethod ||
      !numBuy ||
      !numBuy ||
      !totalPrice
    ) {
      notifyErrorToAddSoldeUser();
    } else {
      let paymentInfoDetails = `${paymentMethod}<br/>${infoDetails}`;
      try {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_CLIENT_URL}/buy`,
          data: {
            userId,
            numBuy,
            jeu,
            server,
            pu,
            qte,
            gameName,
            paymentInfoDetails,
            totalPrice,
            detailUser: userInfo,
            paymentMethod,
            status,
          },
        }).then((res) => {
          // console.log(res?.data);
          dispatch(addNewOrder(res?.data));
          notifySuccessToAddOrder(res?.data?.numBuy);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    isViewProduct && (
      <div className="addnewdofusorder">
        <form onSubmit={handleAddOrder} className="form_addnewsolde">
          <div>
            <div>
              <label htmlFor="">userId</label>
              <input
                type="text"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                placeholder="User ID"
              />
            </div>
            <div>
              <label htmlFor="">N° de commande</label>
              <input
                type="text"
                value={numBuy}
                onChange={(e) => setNumBuy(e.target.value)}
                placeholder="N° de commande"
              />
            </div>
          </div>
          <div>
            <div>
              <label htmlFor="">Jeu</label>
              <select value={jeu} onChange={handleModifJeu}>
                <option value="">Choisissez le catégorie dofus</option>
                <option value="dofus-kamas">Dofus Kamas</option>
                <option value="dofus-retro">Dofus Retro</option>
                <option value="dofus-touch">Dofus Touch</option>
              </select>
            </div>

            <div>
              <label htmlFor="">Serveur</label>
              <select value={server} onChange={handleModifyServer}>
                <option value="">Choisir le serveur</option>
                {serverFiltered?.map((server) => (
                  <option value={server?.serverName}>
                    {server?.serverName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <div>
              <label htmlFor="">Quantité</label>
              <input
                type="number"
                value={qte}
                onChange={(e) => setQte(e.target.value)}
                placeholder="Quantité"
              />
            </div>
            <div>
              <label htmlFor="">Prix unitaire</label>
              <input
                type="number"
                value={pu}
                onChange={(e) => setPu(e.target.value)}
                placeholder="Prix unitaire"
              />
            </div>
          </div>
          <div>
            <div>
              <label htmlFor="paymentmethod">Methodes de paiement:</label>
              <select
                name="paymentmethod"
                id="paymentmethod"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <option value="">Methode de paiement</option>
                <option value="cih bank">CIH Bank</option>
                <option value="attijariwafa bank">Attijariwafa Bank</option>
                <option value="barid bank">Barid Bank</option>
                <option value="Western Union">Western Union</option>
                <option value="Cash Plus">Cash Plus</option>
                <option value="ADV Cash">ADV Cash</option>
                <option value="Cash Plus">Cash Plus</option>
                <option value="Binance Pay">Binance Pay</option>
                <option value="Payeer">Payeer</option>
                <option value="Wise">Wise</option>
                <option value="TRC20">TRC20</option>
              </select>
            </div>

            <div>
              <label htmlFor="">Total</label>
              <input
                type="number"
                value={totalPrice}
                onChange={(e) => setTotalPrice(e.target.value)}
                placeholder="Total"
              />
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div>
              <label htmlFor="">Nom en jeu:</label>
              <input
                type="text"
                value={gameName}
                onChange={(e) => setGameName(e.target.value)}
                placeholder="Nom en jeu"
              />
            </div>

            <div>
              {paymentMethod === "cih bank" ||
              paymentMethod === "attijariwafa bank" ||
              paymentMethod === "barid bank" ? (
                <label htmlFor="fullname">RIB Complet:</label>
              ) : paymentMethod === "Western Union" ||
                paymentMethod === "Cash Plus" ? (
                <label htmlFor="fullname">Votre Ville:</label>
              ) : paymentMethod === "Binance Pay" ||
                paymentMethod === "Payeer" ||
                paymentMethod === "Wise" ? (
                <label htmlFor="fullname">Email de paiement:</label>
              ) : paymentMethod === "ADV Cash" ? (
                <label htmlFor="fullname">Numéro de compte:</label>
              ) : paymentMethod === "TRC20" ? (
                <label htmlFor="fullname">Adresse TRX:</label>
              ) : (
                ""
              )}

              {paymentMethod === "cih bank" ||
              paymentMethod === "attijariwafa bank" ||
              paymentMethod === "barid bank" ? (
                <input
                  type="text"
                  name="fullname"
                  id="fullname"
                  placeholder="Votre RIB"
                  value={infoDetails}
                  onChange={(e) => setInfoDetails(e.target.value)}
                />
              ) : paymentMethod === "Western Union" ||
                paymentMethod === "Cash Plus" ? (
                <input
                  type="text"
                  name="fullname"
                  id="fullname"
                  placeholder="Votre Ville"
                  value={infoDetails}
                  onChange={(e) => setInfoDetails(e.target.value)}
                />
              ) : paymentMethod === "Binance Pay" ||
                paymentMethod === "Payeer" ||
                paymentMethod === "Wise" ? (
                <input
                  type="text"
                  name="fullname"
                  id="fullname"
                  placeholder="Email de paiement"
                  value={infoDetails}
                  onChange={(e) => setInfoDetails(e.target.value)}
                />
              ) : paymentMethod === "ADV Cash" ? (
                <input
                  type="text"
                  name="fullname"
                  id="fullname"
                  placeholder="Numéro de compte"
                  value={infoDetails}
                  onChange={(e) => setInfoDetails(e.target.value)}
                />
              ) : paymentMethod === "TRC20" ? (
                <input
                  type="text"
                  name="fullname"
                  id="fullname"
                  placeholder="Adresse TRX"
                  value={infoDetails}
                  onChange={(e) => setInfoDetails(e.target.value)}
                />
              ) : (
                ""
              )}
            </div>

            <div>
              <label htmlFor="">Status</label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Choisissez le status</option>
                <option value="En attente">En attente</option>
                <option value="Payée">Payée</option>
                <option value="Annulée">Annulée</option>
              </select>
            </div>
          </div>
          <input
            type="submit"
            value="Confirmer commande"
            className="btn-confirm-solde"
          />
        </form>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    )
  );
};

export default AddNewDofusOrder;
