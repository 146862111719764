/* @ts-nocheck */
/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  chfrateiben: 1,
  idchf: "",
};

export const rateChfibenSlice = createSlice({
  name: "chfrateiben",
  initialState,
  reducers: {
    getChfRate: (state, action) => {
      state.chfrateiben = action.payload.chf;
      state.idchf = action.payload.idchf;
      state.loading = false;
    },

    updateChf: (state, action) => {
      state.chfrateiben = action.payload.chf;
    },
  },
});

export const { getChfRate, updateChf } = rateChfibenSlice.actions;

export default rateChfibenSlice.reducer;
