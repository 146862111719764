/* @ts-nocheck */
/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  useriben: {},
  usersiben: null,
};

export const useribenSlice = createSlice({
  name: "useriben",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.loading = false;
      state.useriben = action.payload;
    },
    removeUser: (state) => {
      state.useriben = {};
    },
    updateUser: (state, action) => {
      state.useriben.person = action.payload;
    },

    getAllUsers: (state, action) => {
      state.usersiben = action.payload;
    },

    deleteSingleUser: (state, action) => {
      state.usersiben = state.usersiben.filter(
        (singleUser) => singleUser._id !== action.payload.id
      );
    },
  },
});

export const {
  addUser,
  removeUser,
  updateUser,
  getAllUsers,
  deleteSingleUser,
} = useribenSlice.actions;

export default useribenSlice.reducer;
