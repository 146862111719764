/* @ts-nocheck */
/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { SiShopware } from "react-icons/si";
import { FiShoppingBag, FiEdit } from "react-icons/fi";
import {
  MdOutlineCancel,
  MdOutlineProductionQuantityLimits,
} from "react-icons/md";
import { BsKanban } from "react-icons/bs";
import { AiOutlineShoppingCart, AiOutlineCalendar } from "react-icons/ai";
import { FaUserCog } from "react-icons/fa";
import { MdTextsms } from "react-icons/md";
import { RiExchangeFill, RiContactsLine, RiServerLine } from "react-icons/ri";
import { GiLifeInTheBalance } from "react-icons/gi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { formatDistanceToNow } from "date-fns";

import { io } from "socket.io-client";

import axios from "axios";

// import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";

import notifOrderSound from "../assets/sound/orderNotif.mp3";
import logoNotif from "../assets/logo.png";

const Sidebar = () => {
  const { orders } = useSelector((state) => state.orders);
  const { products } = useSelector((state) => state.exchange);
  const [ordersLenth, setOrdersLength] = useState(
    orders?.filter((order) => order?.status === "En attente").length
  );
  const [exchangesLenth, setExchangesLength] = useState(
    products?.filter((order) => order?.status === "En attente").length
  );

  const [ordersPending, setOrdersPending] = useState(
    orders?.filter((order) => order?.status === "En attente")
  );

  const [exchamgesPending, setExchangesPending] = useState(
    orders?.filter((order) => order?.status === "En attente")
  );

  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();

  const runNotifOrder = () => {
    const audio = new Audio(notifOrderSound);
    audio.play();
  };

  useEffect(() => {
    setOrdersLength(
      orders?.filter((order) => order?.status === "En attente").length
    );
  }, [orders]);

  useEffect(() => {
    setOrdersLength(
      orders?.filter((order) => order?.status === "En attente").length
    );
  }, []);

  useEffect(() => {
    setExchangesLength(
      products?.filter((order) => order?.status === "En attente").length
    );
  }, [products]);

  useEffect(() => {
    setOrdersPending(orders?.filter((order) => order?.status === "En attente"));
  }, [orders]);

  useEffect(() => {
    setExchangesPending(
      products?.filter((order) => order?.status === "En attente")
    );
  }, [products]);

  const { products: ordersIben } = useSelector((state) => state.orderlistiben);
  const [pendingOrders, setPendingOrders] = useState(0);

  // const foragodate = (dateToFormat) => {
  //   const someDate = new Date(dateToFormat);
  //   const timeAgo = formatDistanceToNow(someDate, { addSuffix: true });
  //   let timeFormatted = timeAgo.toString();
  //   let timeSpilt = timeFormatted.replace("ago", "Il y'a").split(" ");
  //   let timeSplited0 = timeSpilt[0];
  //   let timeSplited1 = timeSpilt[1];
  //   let timeSplited2 = timeSpilt[2];

  //   let timeNow = "Il y'a " + timeSplited0 + " " + timeSplited1;
  //   return timeNow;
  // };

  const [notificationsOrder, setNotificationsOrder] = useState([]);
  // console.log(notificationsOrder);
  const [notificationsExchange, setNotificationsExchange] = useState([]);
  // console.log(notificationsExchange);

  const [socket, setSocket] = useState(null);

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  useEffect(() => {
    const getOrdersList = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/getlengthpending`
        )
        .then((res) => setPendingOrders(res?.data));
    };
    getOrdersList();
  }, []);

  const convertDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    return dateConverted;
  };

  useEffect(() => {
    setSocket(io("wss://iby.ibendouma.com"));
  }, []);

  // useEffect(() => {
  //   setSocket(io("ws://localhost:4001"));
  // }, []);

  useEffect(() => {
    socket?.on("new order", (data) => {
      setNotificationsOrder([...notificationsOrder, data]);
      runNotifOrder();
    });
  }, [socket, notificationsOrder]);

  useEffect(() => {
    socket?.on("receive exchange", (data) => {
      setNotificationsExchange([...notificationsExchange, data]);
      runNotifOrder();
    });
  }, [socket, notificationsExchange]);

  const { user } = useSelector((state) => state.user);
  const [userId, setUserId] = useState(user?.user);
  const [displayExchangeNotif, setDisplayExchangeNotif] = useState(false);
  const [displayOrderNotif, setDisplayOrderNotif] = useState(false);

  const handleDisplayExchangeNotif = () => {
    setDisplayExchangeNotif(true);
  };

  const handleRemoveExchangeNotif = () => {
    setDisplayExchangeNotif(false);
  };

  const handleDisplayOrderNotif = () => {
    setDisplayOrderNotif(true);
  };

  const handleRemoveOrderNotif = () => {
    setDisplayOrderNotif(false);
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    userId &&
    user?.person?.isAdmin && (
      <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
        {activeMenu && (
          <div className="flex flex-col items-start">
            <div>
              {/* Ibytrade Components */}
              <div className="flex justify-between items-center">
                <Link
                  to="/"
                  onClick={handleCloseSideBar}
                  className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
                >
                  <SiShopware /> <span>Ibytrade</span>
                </Link>
                <TooltipComponent content="Menu" position="BottomCenter">
                  <button
                    type="button"
                    onClick={() => setActiveMenu(!activeMenu)}
                    style={{ color: currentColor }}
                    className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
                  >
                    <MdOutlineCancel />
                  </button>
                </TooltipComponent>
              </div>
              <div className="mt-10 ">
                <div>
                  <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                    DASHBOARD
                  </p>

                  <NavLink
                    to={`/`}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <RiServerLine />
                    <span className="capitalize">Servers</span>
                  </NavLink>
                  <NavLink
                    to={`/workers`}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <FaUserCog />
                    <span className="capitalize">Workers</span>
                  </NavLink>
                </div>

                <div>
                  <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                    PAGES
                  </p>
                  <div className="flex relative">
                    <NavLink
                      to={`/commandes`}
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <AiOutlineShoppingCart />
                      <span className="capitalize">Orders</span>
                    </NavLink>
                    <span
                      className="flex absolute left-[67%] top-[20%] w-[20px] h-[20px] text-sm p-1 cursor-pointer text-white bg-red-500 items-center justify-center rounded-full"
                      onMouseEnter={handleDisplayOrderNotif}
                      onMouseLeave={handleRemoveOrderNotif}
                    >
                      {ordersLenth ? ordersLenth : 0}
                    </span>
                    <div
                      className={`absolute bg-[#20232a] shadow-2xl rounded-[10px] p-2 text-gray-400 top-[50%] left-[10%] min-w-[250px] min-h-[200px] z-[9999] max-h-[300px] overflow-y-scroll ${
                        displayOrderNotif ? "block" : "hidden"
                      }`}
                      onMouseEnter={handleDisplayOrderNotif}
                      onMouseLeave={handleRemoveOrderNotif}
                    >
                      {ordersPending?.length < 1 ? (
                        <span className=" flex items-center justify-center text-sm mt-20 ml-8">
                          No recent orders
                        </span>
                      ) : (
                        ordersPending?.map((notifOrder) => (
                          <Link to={`/commandes/${notifOrder?.numBuy}`}>
                            <div className="flex items-start gap-2 cursor-pointer pb-4 pt-4 border-b border-gray-500">
                              <img
                                src={logoNotif}
                                alt="user"
                                className="w-[30px] h-[30px] rounded-full bg-cover bg-center"
                              />

                              <div className="flex flex-wrap gap-2">
                                <p className="text-sm">
                                  {`${notifOrder?.detailUser?.lastname} ${notifOrder?.detailUser?.firstname}`}{" "}
                                  just made an order , order number{" "}
                                  <span className="text-sm text-red-500 cursor-pointer">
                                    {notifOrder?.numBuy}
                                  </span>
                                </p>
                                <span className="text-sm text-blue-500 cursor-pointer">
                                  {" "}
                                  {convertDate(notifOrder?.createdAt)}{" "}
                                </span>
                              </div>
                            </div>
                          </Link>
                        ))
                      )}
                    </div>
                  </div>
                  <div className="flex relative">
                    <NavLink
                      to={`/echanges`}
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <RiExchangeFill />
                      <span className="capitalize">Trades</span>
                    </NavLink>
                    <span
                      className="flex absolute left-[67%] top-[20%] w-[20px] h-[20px] text-sm p-1 cursor-pointer text-white bg-red-500 items-center justify-center rounded-full"
                      onMouseEnter={handleDisplayExchangeNotif}
                      onMouseLeave={handleRemoveExchangeNotif}
                    >
                      {exchangesLenth ? exchangesLenth : 0}
                    </span>
                    <div
                      className={`absolute flex flex-col bg-[#20232a] shadow-2xl rounded-[10px] p-2 text-gray-400 top-[50%] left-[10%] min-w-[250px] min-h-[200px] max-h-[300px] overflow-y-scroll z-[9999] ${
                        displayExchangeNotif ? "block" : "hidden"
                      }`}
                      onMouseEnter={handleDisplayExchangeNotif}
                      onMouseLeave={handleRemoveExchangeNotif}
                    >
                      {exchamgesPending?.length < 1 ? (
                        <span className="flex items-center justify-center text-sm mt-20 ml-10">
                          No recent exchanges
                        </span>
                      ) : (
                        exchamgesPending?.map((notifExchange) => (
                          <Link to={`/echanges/${notifExchange?.numExchange}`}>
                            <div className="flex items-start gap-2 cursor-pointer pb-4 pt-4 border-b border-gray-500">
                              <img
                                src={logoNotif}
                                alt="user"
                                className="w-[30px] h-[30px] rounded-full bg-cover bg-center"
                              />

                              <div className="flex flex-wrap gap-2">
                                <p className="text-sm">
                                  {`${notifExchange?.detailUser?.lastname} ${notifExchange?.detailUser?.firstname}`}{" "}
                                  just made an exchange, exchange number{" "}
                                  <span className="text-sm text-red-500 cursor-pointer">
                                    {notifExchange?.numExchange}
                                  </span>
                                </p>
                                <span className="text-sm text-blue-500 cursor-pointer">
                                  {" "}
                                  {convertDate(notifExchange?.createdAt)}{" "}
                                </span>
                              </div>
                            </div>
                          </Link>
                        ))
                      )}
                    </div>
                  </div>

                  <NavLink
                    to={`/soldes`}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <GiLifeInTheBalance />
                    <span className="capitalize">Balances</span>
                  </NavLink>

                  <NavLink
                    to={`/clients`}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <RiContactsLine />
                    <span className="capitalize">Customers</span>
                  </NavLink>
                </div>
              </div>
            </div>

            <div>
              {/* Ibendouma Components */}
              <div className="flex justify-between items-center">
                <Link
                  to="/"
                  onClick={handleCloseSideBar}
                  className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
                >
                  <SiShopware /> <span>Ibendouma</span>
                </Link>
                <TooltipComponent content="Menu" position="BottomCenter">
                  <button
                    type="button"
                    onClick={() => setActiveMenu(!activeMenu)}
                    style={{ color: currentColor }}
                    className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
                  >
                    <MdOutlineCancel />
                  </button>
                </TooltipComponent>
              </div>
              <div className="mt-10 ">
                <div>
                  <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                    DASHBOARD
                  </p>

                  <NavLink
                    to={`/ibendouma/servers`}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <RiServerLine />
                    <span className="capitalize">Servers</span>
                  </NavLink>
                </div>

                <div>
                  <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                    PAGES
                  </p>
                  <div className="flex relative">
                    <NavLink
                      to={`/ibendouma/commandes`}
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <AiOutlineShoppingCart />
                      <span className="capitalize">Orders</span>
                    </NavLink>
                    <span className="flex absolute left-[67%] top-[20%] w-[20px] h-[20px] text-sm p-1 cursor-pointer text-white bg-red-500 items-center justify-center rounded-full">
                      {pendingOrders ? pendingOrders : 0}
                    </span>
                  </div>

                  <NavLink
                    to={`/ibendouma/clients`}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <RiContactsLine />
                    <span className="capitalize">Customers</span>
                  </NavLink>
                  <NavLink
                    to={`/ibendouma/products`}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <MdOutlineProductionQuantityLimits />
                    <span className="capitalize">Products</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default Sidebar;
