/* @ts-nocheck */
/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../components";

import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RiEyeFill, RiAddBoxFill, RiDeleteBin6Line } from "react-icons/ri";
import { RiFileEditLine } from "react-icons/ri";
import { BiAddToQueue, BiDotsHorizontalRounded } from "react-icons/bi";
import { ImPaypal } from "react-icons/im";
import { getOrderList } from "../features/ibendouma/orderibenSlice";

import { Link } from "react-router-dom";

import { BiSearch } from "react-icons/bi";

import { Margin, usePDF, Resolution } from "react-to-pdf";

import {
  BsClockHistory,
  BsFillCheckCircleFill,
  BsPlus,
  BsCheckLg,
} from "react-icons/bs";

import { MdCancel, MdOutlinePendingActions } from "react-icons/md";
import { FaRegListAlt, FaCloudDownloadAlt } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { FiTruck } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

import profilUser from "../assets/default-user.png";

// import noCapture from "../assets/nomage.jpg";

// import FileBase from "react-file-base64";

import ibendoumalogo from "../assets/ibenfact.png";
import cachet from "../assets/cachet.png";

import {
  updateOrder,
  deleteOrder,
} from "../features/ibendouma/ordersibenSlice";
import {
  updateOrderList,
  deleteOrderList,
} from "../features/ibendouma/orderibenSlice";

const IbendoumaOrders = ({
  showPopupiben,
  closePopupiben,
  infoiben,
  getPaypalInfo,
  setPaypalInfoToggle,
  toggleHistoryUpdated,
  setToggleHistoryUpdated,
  setIsViewProduct,
  setIsViewUserId,
  isViewProduct,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.orderlistiben);

  const { toPDF, targetRef } = usePDF({
    filename: "facture.pdf",
    page: { margin: Margin.MEDIUM },
    resolution: Resolution.HIGH,
  });

  // const handlePrint = (e) => {
  //   e.preventDefault();
  //   window.print();
  // };

  // const handlePrint = async () => {
  //   const waitForContentToLoad = () => {
  //     return new Promise((resolve) => {
  //       const contentElement = document.getElementById("pdf-content");

  //       if (contentElement && contentElement.offsetHeight > 0) {
  //         resolve();
  //       } else {
  //         setTimeout(() => {
  //           waitForContentToLoad().then(resolve);
  //         }, 100);
  //       }
  //     });
  //   };

  //   await waitForContentToLoad();
  //   window.print();
  // };

  // console.log(products);

  // console.log(dateFilter);
  // console.log(orders);
  // const [capture, setCapture] = useState("");
  // console.log(orders);
  // console.log(capture);

  const notifyErrorToUpdateOrder = () =>
    toast.error("Veuillez la zone de capture avant de valider");

  const notifySuccessToAddOrder = (name) =>
    toast.success("Commande n° " + name + " mis à jour avec succés");

  const notifySuccessToDeleteOrder = (name) =>
    toast.success("Commande n° " + name + " supprimé avec succés");

  const [toggleSub, setToggleSub] = useState(false);

  const [dataOrder, setDataOrder] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [editableId, setEditableId] = useState(null);
  const [status, setStatus] = useState("En attente");

  const [totalOrderDay, setTotalOrderDay] = useState(0);

  const [month, setMonth] = useState("");
  const [addOrderToggle, setAddOrderToggle] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [beginFilter, setBeginFilter] = useState("En attente");

  const [imagePreview, setImagePreview] = useState(null);
  const [updateHistoryToggle, setUpdateHistoryToggle] = useState(false);
  const [historyUpdated, setHistoryUpdated] = useState([]);
  const [searchTermHistory, setSearchTermHistory] = useState("");

  const [canceledOrders, setCanceledOrders] = useState(0);
  const [inPaymentOrders, setInPaymentOrders] = useState(0);
  const [successOrders, setSuccessOrders] = useState(0);

  const [ordersSuccess, setOrdersSuccess] = useState([]);
  const [ordersCanceled, setOrdersCanceled] = useState([]);
  const [ordersWaited, setOrdersWaited] = useState([]);

  const [orderAll, setOrderAll] = useState(
    [...products || [] , ...ordersSuccess, ...ordersCanceled, ...ordersWaited]?.filter(
      (item) => item
    )
  );

  const [totalOrders, setTotalOrders] = useState(orderAll?.length);
  const [pendingOrders, setPendingOrders] = useState(
    orderAll?.filter((order) => order?.status === "En attente")?.length
  );

  // ORDERS LENGTH GETTING WITH AXIOS

  useEffect(() => {
    const getOrdersList = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/getlengthorders`
        )
        .then((res) => setTotalOrders(res.data));
    };
    getOrdersList();
  }, []);

  useEffect(() => {
    const getOrdersList = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/getlengthsuccess`
        )
        .then((res) => {
          setSuccessOrders(res?.data);
        });
    };
    getOrdersList();
  }, []);

  useEffect(() => {
    const getOrdersList = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/getlengthcanceled`
        )
        .then((res) => setCanceledOrders(res?.data));
    };
    getOrdersList();
  }, []);

  useEffect(() => {
    const getOrdersList = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/getlengthwaited`
        )
        .then((res) => setInPaymentOrders(res?.data));
    };
    getOrdersList();
  }, []);

  // ORDERS GETTING WITH AXIOS

  useEffect(() => {
    const getOrdersList = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/getsuccessorder`
        )
        .then((res) => setOrdersSuccess(res?.data))
        .catch((error) => console.log(error));
    };
    getOrdersList();
  }, []);

  useEffect(() => {
    const getOrdersList = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/getCanceledorder`
        )
        .then((res) => setOrdersCanceled(res?.data));
    };
    getOrdersList();
  }, []);

  useEffect(() => {
    const getOrdersList = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/getwaitedorder`
        )
        .then((res) => setOrdersWaited(res?.data));
    };
    getOrdersList();
  }, []);

  const Status = [
    {
      bg: "bg-orange-200",
      icon: FaRegListAlt,
      color: "text-star",
      title: "Orders",
      total: totalOrders,
    },
    {
      bg: "bg-yellow-200",
      icon: MdOutlinePendingActions,
      color: "text-star",
      title: "Pending",
      total: pendingOrders,
    },
    {
      bg: "bg-blue-200",
      icon: FiTruck,
      color: "text-blue-700",
      title: "In Payment",
      total: inPaymentOrders,
    },
    {
      bg: "bg-green-200",
      icon: BsFillCheckCircleFill,
      color: "text-main",
      title: "Payed",
      total: successOrders,
    },
    {
      bg: "bg-red-200",
      icon: ImCancelCircle,
      color: "text-flash",
      title: "Canceled",
      total: canceledOrders,
    },
  ];

  const monthSelected = [
    {
      id: 11,
      val: "All",
      monthly: "All",
    },
    {
      id: 12,
      val: "01/2023",
      monthly: "January",
    },
    {
      id: 13,
      val: "02/2023",
      monthly: "February",
    },
    {
      id: 14,
      val: "03/2023",
      monthly: "March",
    },
    {
      id: 15,
      val: "04/2023",
      monthly: "April",
    },
    {
      id: 16,
      val: "05/2023",
      monthly: "May",
    },
    {
      id: 17,
      val: "06/2023",
      monthly: "June",
    },
    {
      id: 18,
      val: "07/2023",
      monthly: "July",
    },
    {
      id: 19,
      val: "08/2023",
      monthly: "August",
    },
    {
      id: 20,
      val: "09/2023",
      monthly: "September",
    },
    {
      id: 21,
      val: "10/2023",
      monthly: "October",
    },
    {
      id: 22,
      val: "10/2023",
      monthly: "November",
    },
    {
      id: 23,
      val: "12/2023",
      monthly: "December",
    },
  ];

  useEffect(() => {
    const getOrdersList = async () => {
      await axios
        .get(`${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order`)
        .then((res) => dispatch(getOrderList(res.data)));
    };
    getOrdersList();
  }, [dispatch]);

  const convertDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    return dateConverted;
  };

  const convertDateOnly = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return dateConverted;
  };

  const convertHoursOnly = (date) => {
    let dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      hour: "numeric",
      minute: "numeric",
    });

    let hoursTaked = dateConverted.split(" ")[1];
    return hoursTaked;
  };

  // let day = new Date("17 novembre 2022");
  // console.log(day.getDay());
  // const d = new Date("July 21, 1983 01:15:00");
  // let day = d.getDate() + 3;
  // console.log(day);

  const convertDateAndAddDay = (date) => {
    const myDate = new Date(date);
    const day = myDate.getDay();
    myDate.setDate(day === 6 ? myDate.getDate() + 2 : myDate.getDate() + 1);
    const dateConverted = new Date(myDate).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return dateConverted;
  };

  const handleEditOrder = (orderIdToEdit) => {
    setEditableId(orderIdToEdit);
  };
  const handleDeleteOrder = (orderIdToDelete) => {
    try {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/${orderIdToDelete}`,
      }).then((res) => {
        // console.log(res?.data);
        dispatch(deleteOrderList({ id: res?.data._id }));
        notifySuccessToDeleteOrder(res?.data?.orderNum);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getHistoryChange = () => {
      axios
        .get(
          `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/timetomodifyorder/getinfo`
        )
        .then((res) => {
          setHistoryUpdated(res?.data);
        })
        .catch((err) => console.log(err));
    };

    getHistoryChange();
  }, []);

  useEffect(() => {
    setCanceledOrders(
      orderAll?.filter((order) => order.status === "Annulée")?.length
    );
    setPendingOrders(
      orderAll?.filter((order) => order.status === "En attente")?.length
    );
    setInPaymentOrders(
      orderAll?.filter((order) => order.status === "En Cours de payment")
        ?.length
    );
    setSuccessOrders(
      orderAll?.filter((order) => order.status === "Terminée")?.length
    );
  }, [orderAll, products]);

  const handleUpdateOrders = (idOrderToUpdate) => {
    if (!status) {
      notifyErrorToUpdateOrder();
    } else {
      try {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/${idOrderToUpdate}`,
          data: {
            status,
          },
        }).then((res) => {
          dispatch(updateOrderList(res?.data));
          notifySuccessToAddOrder(res?.data?.orderNum);
          setEditableId(null);

          axios({
            method: "post",
            url: `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/userupdate/modify`,
            data: {
              userId: user?.person?._id,
              oderNum: res?.data?.orderNum,
              firstname: user?.person?.firstname,
              lastname: user?.person?.lastname,
              email: user?.person?.email,
              status: res?.data?.status,
            },
          });
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleCancleModifOrders = () => {
    setEditableId(null);
  };

  const handleToggleSubstr = (chaine) => {
    if (!toggleSub) {
      return chaine.substring(0, 17);
    } else {
      return chaine;
    }
  };

  const handleDisplayAddingOrder = (userIdOrder) => {
    setIsViewUserId(userIdOrder);
    setIsViewProduct(true);
    setAddOrderToggle(false);
  };

  const handleBasculeToggle = () => {
    setToggleSub((prevToggleSub) => !prevToggleSub);
  };

  const handleFilterOrder = (titleOrderToFilter) => {
    if (titleOrderToFilter === "Pending") {
      setBeginFilter("En attente");
    } else if (titleOrderToFilter === "Payed") {
      setBeginFilter("Terminée");
    } else if (titleOrderToFilter === "In Payment") {
      setBeginFilter("En Cours de payment");
    } else if (titleOrderToFilter === "Canceled") {
      setBeginFilter("Annulée");
    } else if (titleOrderToFilter === "Orders") {
      setBeginFilter("");
    } else {
      setBeginFilter("");
    }
  };

  const handleMonthSelect = (e) => {
    setMonth(e.target.value);
    if (e.target.value === "All") {
      setOrderAll(products);
    } else {
      setOrderAll(
        products?.filter(
          (product) => convertMonthDate(product?.createdAt) === e.target.value
        )
      );
    }
  };

  const convertMonthDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
    });
    return dateConverted;
  };

  const convertDatePdf = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return dateConverted;
  };

  const handleViewPaypalPayInfo = (paypalId) => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/paypal/get-paypal-order-details`,
      data: {
        orderID: paypalId,
      },
    })
      .then((res) => {
        getPaypalInfo(res?.data);
        setPaypalInfoToggle(true);
      })
      .catch((err) => console.log(err));
  };

  const Head = "text-xs font-semibold px-6 py-2 uppercase";
  const Text = "px-5 text-sm py-3 leading-6 whitespace-nowrap";

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-4 bg-white rounded-3xl dark:bg-secondary-dark-bg ordersiben">
      <div className="grid lg:grid-cols-5 sm:grid-cols-3 gap-2 bg-white rounded mx-2">
        {Status.map((status, i) => (
          <div
            key={i}
            className="p-2 rounded grid grid-cols-4 gap-2 cursor-pointer"
            onClick={() => handleFilterOrder(status?.title)}
          >
            <div
              className={`rounded-full flex items-center justify-center col-span-1 text-lg w-12 h-12 ${status.bg} ${status.color}`}
            >
              <status.icon />
            </div>
            <div className="col-span-3">
              <h2>{status.title}</h2>
              <p className="font-bold mt-2">{status.total}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="relative flex items-center justify-between flex-wrap mx-2 mb-[0px] mt-8">
        <div
          className="rounded select-none flex items-center gap-2 w-36 p-2 bg-yellow-600 text-white cursor-pointer mt-8"
          onClick={() => setToggleHistoryUpdated(true)}
        >
          <span>Update history</span>
          <span>
            <BsClockHistory />
          </span>
          {toggleHistoryUpdated && (
            <div className="absolute p-4 rounded flex flex-col z-[10000] top-[120%] left-[50%] -translate-x-[50%] w-[800px] overflow-y-scroll max-h-[600px] history">
              <div className="w-full relative">
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={searchTermHistory}
                  onChange={(e) => setSearchTermHistory(e.target.value)}
                  placeholder="Search by Order N°"
                  className="text-base p-2 rounded w-full border-none text-gray-400 bg-gray-900"
                />
                <span className="absolute text-gray-400 text-3xl left-[94%] top-[15%]">
                  <BiSearch />
                </span>
              </div>

              <table className="table-auto w-full border border-deepest divide-y divide-gray-200">
                <thead className="bg-gray-900">
                  <tr className="bg-deepest">
                    <th scope="col" className={`${Head} text-left`}>
                      Order N°
                    </th>
                    <th scope="col" className={`${Head} text-center`}>
                      Edited by
                    </th>
                    <th scope="col" className={`${Head} text-center`}>
                      Modifier Email
                    </th>

                    <th scope="col" className={`${Head} text-center`}>
                      Modification date
                    </th>
                    <th scope="col" className={`${Head} text-center`}>
                      Modified Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-gray-700 divide-y divide-deepest">
                  {historyUpdated
                    ?.filter((history) =>
                      history?.oderNum
                        .toLowerCase()
                        .includes(searchTermHistory.toLowerCase())
                    )
                    ?.map((history) => (
                      <tr key={history?._id}>
                        <td className={`${Text} font-medium`}>
                          {history?.oderNum}
                        </td>
                        <td className={`${Text} text-center`}>
                          {`${history?.lastname} ${history?.firstname}`}
                        </td>
                        <td className={`${Text} text-center`}>
                          {history?.email}
                        </td>
                        <td className={`${Text} text-center`}>
                          {convertDate(history?.createdAt)}
                        </td>
                        <td className={`${Text} text-center`}>
                          <p
                            className={
                              history?.status === "Payée"
                                ? "success"
                                : history?.status === "Annulée"
                                ? "no-success"
                                : history?.status === "En Cours de payment"
                                ? "payment-success"
                                : history?.status === "En attente"
                                ? "pending"
                                : "no-pending"
                            }
                          >
                            {history?.status === "Annulée" && "Canceled"}
                            {history?.status === "En attente" && "Pending"}
                            {history?.status === "Payée" && "Payed"}
                            {history?.status === "En Cours de payment" &&
                              "In payment"}
                          </p>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="infos-user-search">
          <input
            type="text"
            name="search"
            id="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="N° Order"
            className="xl:w-[600px] w-full"
          />
          <span className="absolute text-slate-400 text-3xl left-[80%] xl:left-[94%] top-[50%] -translate-y-[50%]">
            <BiSearch />
          </span>
        </div>
        <div className="flex flex-col cursor-pointer mb-2">
          {/*<div
            className="flex items-center gap-2 w-72 p-2 bg-yellow-600 text-white rounded-tl rounded-tr rounded-br"
            onClick={() => generatePdf()}
            style={{
              display:
                user?.person?.email === "test@ibendoumatest.com"
                  ? "none"
                  : "flex",
            }}
          >
            <span>Download transaction statement</span>
            <span>
              <FaCloudDownloadAlt />
            </span>
          </div>*/}
          <select
            name="month"
            id="month"
            className="outline-none w-72 p-1 cursor-pointer bg-green-500 text-white rounded-tr rounded-br mt-8"
            value={month}
            onChange={handleMonthSelect}
          >
            <option value="Select month">Select month</option>
            {monthSelected?.map((wmonth) => (
              <option key={wmonth?.id} value={wmonth?.val}>
                {wmonth?.monthly}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="table_responsive">
        <table>
          <thead>
            <tr>
              <th>N° Order</th>
              <th>Product</th>
              {/* <th>Dofus</th> */}
              {/* <th>Serveur</th>
              <th>Qte</th> */}
              <th>Price</th>
              {/* <th>Catégorie</th> */}
              {/* <th>Personnage</th> */}
              <th>Total</th>
              <th>Status</th>
              {/* {user?.person?.isAdmin && <th>Coordonnées de paiement</th>} */}
              <th>Date</th>
              {/* <th>Paiement method</th> */}
              {/* <th>Email</th> */}
              <th
                style={{
                  display:
                    user?.person?.email === "test@ibendoumatest.com"
                      ? "none"
                      : "flex",
                }}
              >
                Actions
              </th>
              {/* <th>Info Pay</th> */}
            </tr>
          </thead>
          <tbody>
            {orderAll
              ?.filter(
                (order) => beginFilter === "" || order.status === beginFilter
              )
              ?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
              ?.filter((order) => order?.orderNum.includes(searchTerm))
              ?.map((order) => (
                <tr key={order._id}>
                  <td>
                    <p className="order-sales">{order.orderNum}</p>
                  </td>
                  {order?.products?.map((signle) => (
                    <>
                      <td
                        className={
                          signle.category === "dofus-kamas"
                            ? "dofuskamas"
                            : signle.category === "dofus-touch"
                            ? "dofustouch"
                            : "dofusretro"
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "100px",
                            height: "40px",
                          }}
                        >
                          <p className="-mt-1">
                            {order?.products[0]?.category}-
                            {order?.products[0]?.server}
                          </p>
                          <p className="-mt-1">
                            Character: {order?.products[0]?.character}
                          </p>
                          <p className="-mt-1">
                            Quantity: {order?.products[0]?.amount}M
                          </p>
                        </div>
                      </td>
                      {/* <td>
                        <img
                          src={signle?.image ? signle?.image : notFound}
                          alt="profil"
                        />
                      </td> */}
                      {/* <td>{signle.server}</td>
                      <td>{signle.amount}M</td> */}
                      <td>{signle.price}</td>
                      {/* <td>{signle.category}</td> */}
                      {/* <td>{signle.character}</td> */}
                      <td>
                        {(signle?.price * signle?.amount).toFixed(2)}
                        <span className="uppercase ml-[1px] font-semibold">
                          {order?.cur === "euro" && "EUR"}
                          {order?.cur === "dollar" && "USD"}
                          {order?.cur === "mad" && "MAD"}
                          {order?.cur === "usdt" && "USDT"}
                          {order?.cur === "cad" && "CAD"}
                          {order?.cur === "chf" && "CHF"}
                          {order?.cur === "rub" && "RUB"}
                          {order?.cur === "gbp" && "GBP"}
                        </span>
                      </td>
                    </>
                  ))}
                  {order?._id === editableId ? (
                    <td>
                      {" "}
                      <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="En attente">Pending</option>
                        <option value="En Cours de payment">In payment</option>
                        <option value="Terminée">Payed</option>
                        <option value="Annulée">Canceled</option>
                      </select>{" "}
                    </td>
                  ) : (
                    <td
                      className={
                        order.status === "Terminée"
                          ? "success"
                          : order.status === "Annulée"
                          ? "no-success"
                          : order.status === "En Cours de payment"
                          ? "payment-success"
                          : order.status === "En attente"
                          ? "pending"
                          : "no-pending"
                      }
                    >
                      {order.status === "En attente" && "Pending"}
                      {order.status === "En Cours de payment" && "In payment"}
                      {order.status === "Terminée" && "Payed"}
                      {order.status === "Annulée" && "Canceled"}
                    </td>
                  )}
                  <td>{convertDate(order.createdAt)}</td>

                  {/* {order?.paymentMethod ? (
                    <td>{order?.paymentMethod}</td>
                  ) : (
                    <td></td>
                  )} */}

                  {/* {order?.detailUser ? (
                    <td>{order?.detailUser?.email}</td>
                  ) : (
                    <td></td>
                  )} */}

                  {order?._id === editableId ? (
                    <td>
                      <div className="action_btn">
                        <button
                          className="servers-valid"
                          onClick={() => handleUpdateOrders(order?._id)}
                        >
                          Valider
                        </button>
                        <button
                          className="servers-cancel"
                          onClick={handleCancleModifOrders}
                        >
                          Annuler
                        </button>
                      </div>
                    </td>
                  ) : (
                    <td style={{ width: "101px" }}>
                      <div
                        className="action_btn"
                        style={{
                          display:
                            user?.person?.email === "test@ibendoumatest.com"
                              ? "none"
                              : "flex",
                        }}
                      >
                        <span
                          className="servers-delete"
                          onClick={() => handleDeleteOrder(order?._id)}
                        >
                          <RiDeleteBin6Line />
                        </span>
                        <span
                          className="servers-edit"
                          onClick={() => handleEditOrder(order?._id)}
                        >
                          <RiFileEditLine />
                        </span>

                        <span
                          className="servers-view"
                          onClick={() => closePopupiben(order?.orderNum, true)}
                        >
                          <RiEyeFill />
                        </span>
                      </div>
                    </td>
                  )}
                  {/* <td>
                    {order?.orderIdPaid && (
                      <span
                        className="servers-paypal"
                        onClick={() =>
                          handleViewPaypalPayInfo(order?.orderIdPaid)
                        }
                      >
                        <ImPaypal
                          style={{
                            fontSize: "18px",
                            color: "#1e4db7",
                            cursor: "pointer",
                            transition: "0.3s ease-in-out",
                            marginTop: "2px",
                          }}
                        />
                      </span>
                    )}
                  </td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="popup-container">
        {showPopupiben && (
          <div className="popup" ref={targetRef}>
            <div className="flex w-full flex-col items-center">
              <div className="flex w-full items-center">
                <img
                  src={ibendoumalogo}
                  alt="logo_ibendouma"
                  className="w-[120px] h-[120px] object-cover object-center"
                />
                <div className="h-[100px] w-[1px] bg-black mx-6"></div>
                <div className="flex flex-col items-start gap-1">
                  <p className="text-sm font-bold">IBENDOUMA LTD</p>
                  <p className="text-sm font-bold">N°{infoiben?.orderNum}</p>
                  <span className="text-sm max-w-[250px]">
                    71-75 Shelton Street, Covent Garden, Londres, Royaume Uni,
                    WC2H 9JQ
                  </span>
                </div>
                <div className="h-[100px] w-[1px] bg-black mx-6"></div>
                <div className="flex flex-col items-start gap-1">
                  <p className="text-sm">Téléphone: (+44) 1273 90 1262</p>
                  <p className="text-sm cursor-pointer">
                    Site web: https://ibendouma.com/
                  </p>
                </div>
              </div>
              <h2 className="uppercase text-[26px] tracking-wider font-semibold my-2">
                Facture
              </h2>
              <div className="w-full flex items-start justify-between">
                <div className="flex flex-col items-start text-sm">
                  <p>
                    {`${infoiben?.detailUser?.lastname} ${infoiben?.detailUser?.firstname}`}
                  </p>
                  <p>
                    {infoiben?.detailUser?.city},{" "}
                    {infoiben?.detailUser?.address}{" "}
                    {infoiben?.detailUser?.country}
                    {infoiben?.detailUser?.postalCode}
                  </p>
                  <p>pays d'origine: {infoiben?.detailUser?.country}</p>
                  <p></p>
                </div>
                <div className="flex flex-col items-start text-sm">
                  <p className="flex items-center">
                    Facture N°
                    <span className="ml-12">:I{infoiben?.orderNum}</span>
                  </p>
                  <p className="flex items-center">
                    Date
                    <span className="ml-[83px]">
                      :{convertDateOnly(infoiben?.createdAt)}
                    </span>
                  </p>
                  <p className="flex items-center">
                    Heure
                    <span className="ml-[75px]">
                      :{convertHoursOnly(infoiben?.createdAt)}
                    </span>
                  </p>
                </div>
              </div>
              <div className="w-full flex-col items-center my-10">
                <div className="w-full flex items-center justify-between pb-2 border-b-[2px] border-black">
                  <p className="uppercase text-sm text-yellow-600">
                    description des produits
                  </p>
                  <p className="uppercase text-sm text-yellow-600">qte</p>
                  <p className="uppercase text-sm text-yellow-600">
                    sous total({infoiben?.cur ? infoiben.cur : "eur"})
                  </p>
                </div>
                <div className="w-full flex items-center justify-between">
                  <p className="uppercase text-sm">
                    {`${
                      infoiben?.products && infoiben?.products[0]?.category
                    } ${infoiben?.products && infoiben?.products[0]?.server}`}
                  </p>
                  <p className="uppercase text-sm -ml-[64px]">
                    {infoiben?.products && infoiben?.products[0]?.amount}M
                  </p>
                  <p className="uppercase text-sm">{infoiben?.totalPrice}</p>
                </div>
              </div>
              <div className="w-full flex-col items-center">
                <div className="w-full flex items-center justify-between bg-white shadow-md p-1 text-sm border-t-[2px] border-slate-300">
                  <p className="invisible">sous total</p>
                  <p>Sous total</p>
                  <p>{infoiben?.totalPrice}</p>
                </div>
                <div className="w-full flex text-center items-center justify-between p-1 text-sm">
                  <p className="invisible">sous total</p>
                  <p className="text-base font-bold">
                    Montant total de la facture
                  </p>
                  <p className="text-base font-bold">{infoiben?.totalPrice}</p>
                </div>
              </div>
              <div className="w-full flex items-center justify-center border-b border-dashed border-black my-6"></div>
              <h2 className="uppercase text-[20px] tracking-wider font-semibold my-2">
                Avis de paiement
              </h2>
              <div className="w-full flex-col items-center my-10">
                <div className="w-full flex items-center justify-between pb-2 border-b-[2px] border-black">
                  <p className="flex flex-col items-start text-sm">
                    <p className="text-sm -mt-1 font-semibold">
                      A:
                      {`${infoiben?.detailUser?.lastname} ${infoiben?.detailUser?.firstname}`}
                    </p>
                    <p className="uppercase text-sm text-yellow-600">
                      description des produits
                    </p>
                  </p>

                  <p className="uppercase text-sm text-yellow-600">
                    subtotal({infoiben?.cur ? infoiben.cur : "eur"})
                  </p>
                </div>
                <div className="w-full flex items-center justify-between mt-2">
                  <p className="uppercase text-sm">
                    Montant total de la facture N°I{infoiben?.orderNum}
                  </p>
                  <p className="uppercase text-sm">{infoiben?.totalPrice}</p>
                </div>
                <div className="w-full flex items-center justify-between mt-2">
                  <p className="uppercase text-sm">
                    Déduction des charges de la facture N°I{infoiben?.orderNum}
                  </p>
                  <p className="uppercase text-sm">0</p>
                </div>
                <div className="w-full flex items-center justify-between mt-2 p-1 text-sm">
                  <p className="text-base font-bold">Paiement du solde net</p>
                  <p className="text-base font-bold">{infoiben?.totalPrice}</p>
                </div>
              </div>
              <div className="w-full flex items-center justify-between -mt-6">
                <button
                  className="text-sm p-2 rounded text-blue-600 font-semibold shadow-inner transition duration-500 ease-in-out hover:bg-blue-700 hover:text-white"
                  onClick={() => toPDF()}
                >
                  Télécharger en PDF
                </button>
                <img
                  src={cachet}
                  alt="cachet_ibendouma"
                  className="flex items-end justify-end w-[50px] h-[50px] object-cover rounded"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
export default IbendoumaOrders;
