/* @ts-nocheck */
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../components";

import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RiDeleteBin6Line } from "react-icons/ri";
import { RiFileEditLine, RiEyeFill } from "react-icons/ri";
import {
  BiAddToQueue,
  BiDotsHorizontalRounded,
  BiSearch,
} from "react-icons/bi";
import {
  BsCheckLg,
  BsClockHistory,
  BsFillCheckCircleFill,
  BsPlus,
} from "react-icons/bs";
import { MdCancel, MdOutlinePendingActions } from "react-icons/md";
import { FaRegListAlt, FaCloudDownloadAlt } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { FiTruck } from "react-icons/fi";

import { getOrders } from "../features/ordersSlice";

// import go from "../assets/go.png";

// import noCapture from "../assets/nomage.jpg";

// import FileBase from "react-file-base64";
import ibendoumalogo from "../assets/ibenfact.png";
import cachet from "../assets/cachet.png";

import { Margin, usePDF, Resolution } from "react-to-pdf";

import { updateOrder, deleteOrder } from "../features/ordersSlice";
import { Link, useParams } from "react-router-dom";
import { IoMdClose } from "react-icons/io";

const Orders = ({
  toggleHistoryUpdated,
  setToggleHistoryUpdated,
  setIsViewProduct,
  setIsViewUserId,
  isViewProduct,
}) => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.orders);
  const { users } = useSelector((state) => state.user);
  // console.log(users)

  const { user } = useSelector((state) => state.user);

  const { commandId } = useParams();
  const [orderAll, setOrderAll] = useState(orders);
  const [imagePreview, setImagePreview] = useState(null);
  const [updateHistoryToggle, setUpdateHistoryToggle] = useState(false);
  const [historyUpdated, setHistoryUpdated] = useState([]);
  const [searchTermHistory, setSearchTermHistory] = useState("");
  const [beginFilter, setBeginFilter] = useState("En attente");

  const { toPDF, targetRef } = usePDF({
    filename: "facture.pdf",
    page: { margin: Margin.MEDIUM },
    resolution: Resolution.HIGH,
  });

  const notifyErrorToUpdateOrder = () =>
    toast.error("Veuillez la zone de capture avant de valider");

  const notifySuccessToAddOrder = (name) =>
    toast.success("Order N° " + name + " successfully updated");

  const notifySuccessToDeleteOrder = (name) =>
    toast.success("Order N° " + name + " successfully updated");

  const [toggleSub, setToggleSub] = useState(false);

  const [dataOrder, setDataOrder] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [editableId, setEditableId] = useState(null);
  const [status, setStatus] = useState("En attente");
  const [totalOrderDay, setTotalOrderDay] = useState(0);
  const [month, setMonth] = useState("");
  const [orderFactureToggle, setOrderFactureToggle] = useState(false);
  const [orderInvoice, setOrderInvoice] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [addOrderToggle, setAddOrderToggle] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalOrders, setTotalOrders] = useState(orderAll?.length);
  const [canceledOrders, setCanceledOrders] = useState(
    orderAll?.filter((order) => order.status === "Annulée")?.length
  );
  const [pendingOrders, setPendingOrders] = useState(
    orderAll?.filter((order) => order.status === "En attente")?.length
  );
  const [inPaymentOrders, setInPaymentOrders] = useState(
    orderAll?.filter((order) => order.status === "En Cours de payment")?.length
  );
  const [successOrders, setSuccessOrders] = useState(
    orderAll?.filter((order) => order.status === "Payée")?.length
  );

  const [pendingBegin, setPendingBegin] = useState([]);

  const [dataList, setDataList] = useState([
    { id: 1, name: "Item 1" },
    { id: 2, name2: "Item 2" },
    { id: 3, name3: "Item 3" },
  ]);

  const Status = [
    {
      bg: "bg-orange-200",
      icon: FaRegListAlt,
      color: "text-star",
      title: "Orders",
      total: totalOrders,
    },
    {
      bg: "bg-yellow-200",
      icon: MdOutlinePendingActions,
      color: "text-star",
      title: "Pending",
      total: pendingOrders,
    },
    {
      bg: "bg-blue-200",
      icon: FiTruck,
      color: "text-blue-700",
      title: "In Payment",
      total: inPaymentOrders,
    },
    {
      bg: "bg-green-200",
      icon: BsFillCheckCircleFill,
      color: "text-main",
      title: "Payed",
      total: successOrders,
    },
    {
      bg: "bg-red-200",
      icon: ImCancelCircle,
      color: "text-flash",
      title: "Canceled",
      total: canceledOrders,
    },
  ];

  useEffect(() => {
    const getHistoryChange = () => {
      axios
        .get(
          `${process.env.REACT_APP_CLIENT_URL}/buy/timetomodifyorder/getinfo`
        )
        .then((res) => {
          setHistoryUpdated(res?.data);
        })
        .catch((err) => console.log(err));
    };

    getHistoryChange();
  }, []);

  const handleFilterOrder = (titleOrderToFilter) => {
    if (titleOrderToFilter === "Pending") {
      setBeginFilter("En attente");
    } else if (titleOrderToFilter === "Payed") {
      setBeginFilter("Payée");
    } else if (titleOrderToFilter === "In Payment") {
      setBeginFilter("En Cours de payment");
    } else if (titleOrderToFilter === "Canceled") {
      setBeginFilter("Annulée");
    } else if (titleOrderToFilter === "Orders") {
      setBeginFilter("");
    } else {
      setBeginFilter("");
    }
  };

  const monthSelected = [
    {
      id: 11,
      val: "All",
      monthly: "All",
    },
    {
      id: 12,
      val: "01/2023",
      monthly: "January",
    },
    {
      id: 13,
      val: "02/2023",
      monthly: "February",
    },
    {
      id: 14,
      val: "03/2023",
      monthly: "March",
    },
    {
      id: 15,
      val: "04/2023",
      monthly: "April",
    },
    {
      id: 16,
      val: "05/2023",
      monthly: "May",
    },
    {
      id: 17,
      val: "06/2023",
      monthly: "June",
    },
    {
      id: 18,
      val: "07/2023",
      monthly: "July",
    },
    {
      id: 19,
      val: "08/2023",
      monthly: "August",
    },
    {
      id: 20,
      val: "09/2023",
      monthly: "September",
    },
    {
      id: 21,
      val: "10/2023",
      monthly: "October",
    },
    {
      id: 22,
      val: "10/2023",
      monthly: "November",
    },
    {
      id: 23,
      val: "12/2023",
      monthly: "December",
    },
  ];

  const handleAddOrder = () => {
    setAddOrderToggle(true);
  };
  const handleCancelOrder = (e) => {
    e.stopPropagation();
    setAddOrderToggle(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const getAllOrders = async () => {
      await axios.get(`${process.env.REACT_APP_CLIENT_URL}/buy`).then((res) => {
        dispatch(getOrders(res?.data));
      });
    };
    getAllOrders();
  }, [dispatch]);

  const convertDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    return dateConverted;
  };

  const convertMonthDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
    });
    return dateConverted;
  };

  const handleMonthSelect = (e) => {
    setMonth(e.target.value);
    if (e.target.value === "All") {
      setOrderAll(orders);
    } else {
      setOrderAll(
        orders?.filter(
          (order) => convertMonthDate(order?.createdAt) === e.target.value
        )
      );
    }
  };

  const handleDisplayFacture = (orderId) => {
    setOrderFactureToggle(true);
    axios
      .get(`${process.env.REACT_APP_CLIENT_URL}/buy/${orderId}`)
      .then((res) => setOrderInvoice(res.data))
      .catch((error) => console.log(error));
  };

  const handleCancelInvoiceToggle = (e) => {
    e.stopPropagation();
    setOrderFactureToggle(false);
  };

  useEffect(() => {
    let ordersPending = [];
    ordersPending = orders?.filter((order) => order?.status === "En attente");
    setPendingBegin(ordersPending);
  }, []);

  useEffect(() => {
    setOrderAll(orders?.filter((order) => order?.numBuy?.includes(searchTerm)));
  }, [searchTerm]);

  const convertDateAndAddDay = (date) => {
    const myDate = new Date(date);
    const day = myDate.getDay();
    myDate.setDate(day === 6 ? myDate.getDate() + 2 : myDate.getDate() + 1);
    const dateConverted = new Date(myDate).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return dateConverted;
  };

  const convertDateOnly = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return dateConverted;
  };

  const convertHoursOnly = (date) => {
    let dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      hour: "numeric",
      minute: "numeric",
    });

    let hoursTaked = dateConverted.split(" ")[1];
    return hoursTaked;
  };

  const recupBonus = (bonusPrice) => {
    let bonus = bonusPrice;
    let bonusToRender = bonus;
    if (bonus > 3000) {
      bonusToRender = bonus - 50;
    } else {
      bonusToRender = bonus;
    }
    return bonusToRender;
  };

  const getBonus = (bonusToGet) => {
    let bonus = bonusToGet;
    let myBonus = 0;
    if (bonus > 3000) {
      myBonus = 50;
    } else {
      myBonus = 0;
    }
    return myBonus;
  };

  const handleEditOrder = (orderIdToEdit) => {
    setEditableId(orderIdToEdit);
  };
  const handleDeleteOrder = (orderIdToDelete) => {
    try {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_CLIENT_URL}/buy/${orderIdToDelete}`,
      }).then((res) => {
        dispatch(deleteOrder({ id: res?.data._id }));
        notifySuccessToDeleteOrder(res?.data?.numBuy);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateOrders = async (idOrderToUpdate) => {
    if (!status) {
      notifyErrorToUpdateOrder();
    } else {
      try {
        await axios({
          method: "put",
          url: `${process.env.REACT_APP_CLIENT_URL}/buy/${idOrderToUpdate}`,
          data: {
            status,
          },
        }).then((res) => {
          dispatch(updateOrder(res?.data));
          notifySuccessToAddOrder(res?.data?.numBuy);
          setEditableId(null);

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/buy/userupdate/modify`,
            data: {
              userId: user?.person?._id,
              oderNum: res?.data?.numBuy,
              firstname: user?.person?.firstname,
              lastname: user?.person?.lastname,
              email: user?.person?.email,
              status: res?.data?.status,
            },
          });
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setCanceledOrders(
      orderAll?.filter((order) => order.status === "Annulée")?.length
    );
    setPendingOrders(
      orderAll?.filter((order) => order.status === "En attente")?.length
    );
    setInPaymentOrders(
      orderAll?.filter((order) => order.status === "En Cours de payment")
        ?.length
    );
    setSuccessOrders(
      orderAll?.filter((order) => order.status === "Payée")?.length
    );
  }, [orderAll, orders]);

  const handleCancleModifOrders = () => {
    setEditableId(null);
  };

  const handleToggleSubstr = (chaine) => {
    if (!toggleSub) {
      return chaine.substring(0, 17);
    } else {
      return chaine;
    }
  };

  const handleBasculeToggle = () => {
    setToggleSub((prevToggleSub) => !prevToggleSub);
  };

  useEffect(() => {
    if (commandId) {
      setOrderAll(
        orders?.filter((order) => order?.numBuy?.includes(commandId))
      );
    } else {
      setOrderAll(orders);
    }
  }, [commandId, orders]);

  const handleDisplayAddingOrder = (userIdOrder) => {
    setIsViewUserId(userIdOrder);
    setIsViewProduct(true);
    setAddOrderToggle(false);
  };

  const Head = "text-xs font-semibold px-6 py-2 uppercase";
  const Text = "px-5 text-sm py-3 leading-6 whitespace-nowrap";

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-2 bg-white rounded-3xl dark:bg-secondary-dark-bg orders">
      <div className="grid lg:grid-cols-5 sm:grid-cols-3 gap-2 bg-white rounded mx-2">
        {Status.map((status, i) => (
          <div
            key={i}
            className="p-2 rounded grid grid-cols-4 gap-2 cursor-pointer"
            onClick={() => handleFilterOrder(status?.title)}
          >
            <div
              className={`rounded-full flex items-center justify-center col-span-1 text-lg w-12 h-12 ${status.bg} ${status.color}`}
            >
              <status.icon />
            </div>
            <div className="col-span-3">
              <h2>{status.title}</h2>
              <p className="font-bold mt-2">{status.total}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="relative flex items-end justify-end mx-2 mb-[0px] mt-8">
        <div
          className="xl:mr-[302px] mr-[10px] rounded select-none flex items-center gap-2 w-36 p-2 bg-yellow-600 text-white cursor-pointer"
          onClick={() => setToggleHistoryUpdated(true)}
        >
          <span>Update history</span>
          <span>
            <BsClockHistory />
          </span>
          {toggleHistoryUpdated && (
            <div className="absolute p-4 rounded flex flex-col z-[10000] top-[120%] left-[50%] -translate-x-[50%] w-[800px] overflow-y-scroll max-h-[600px] history">
              <div className="w-full relative">
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={searchTermHistory}
                  onChange={(e) => setSearchTermHistory(e.target.value)}
                  placeholder="Search by Order N°"
                  className="text-base p-2 rounded w-full  border-none text-gray-400 bg-gray-900"
                />
                <span className="absolute text-gray-400 text-3xl left-[94%] top-[15%]">
                  <BiSearch />
                </span>
              </div>

              <table className="table-auto w-full border border-deepest divide-y divide-gray-200">
                <thead className="bg-gray-900">
                  <tr className="bg-deepest">
                    <th scope="col" className={`${Head} text-left`}>
                      Order N°
                    </th>
                    <th scope="col" className={`${Head} text-center`}>
                      Edited by
                    </th>
                    <th scope="col" className={`${Head} text-center`}>
                      Modifier Email
                    </th>

                    <th scope="col" className={`${Head} text-center`}>
                      Modification date
                    </th>
                    <th scope="col" className={`${Head} text-center`}>
                      Modified Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-gray-700 divide-y divide-deepest">
                  {historyUpdated
                    ?.filter((history) =>
                      history?.oderNum
                        .toLowerCase()
                        .includes(searchTermHistory.toLowerCase())
                    )
                    ?.map((history) => (
                      <tr key={history?._id}>
                        <td className={`${Text} font-medium`}>
                          {history?.oderNum}
                        </td>
                        <td className={`${Text} text-center`}>
                          {`${history?.lastname} ${history?.firstname}`}
                        </td>
                        <td className={`${Text} text-center`}>
                          {history?.email}
                        </td>
                        <td className={`${Text} text-center`}>
                          {convertDate(history?.createdAt)}
                        </td>
                        <td className={`${Text} text-center`}>
                          <p
                            className={
                              history?.status === "Payée"
                                ? "success"
                                : history?.status === "Annulée"
                                ? "no-success"
                                : history?.status === "En Cours de payment"
                                ? "payment-success"
                                : history?.status === "En attente"
                                ? "pending"
                                : "no-pending"
                            }
                          >
                            {history?.status === "Annulée" && "Canceled"}
                            {history?.status === "En attente" && "Pending"}
                            {history?.status === "Payée" && "Payed"}
                            {history?.status === "En Cours de payment" &&
                              "In payment"}
                          </p>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="infos-user-search">
          <input
            type="text"
            name="search"
            id="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="N° Order"
          />
          <span className="search_addnewsolde">
            <BiSearch />
          </span>
        </div>
        <div
          className="flex items-center gap-1 bg-blue-600 text-white p-2 cursor-pointer rounded-tl rounded-bl"
          onClick={handleAddOrder}
          style={{
            visibility:
              user?.person?.email === "test@ibendoumatest.com"
                ? "hidden"
                : "visible",
          }}
        >
          <span>Add Order</span>
          <span>
            <BsPlus />
          </span>
          {addOrderToggle && !isViewProduct && (
            <div className="absolute flex flex-col items-center bg-gray-400 shadow-2xl rounded text-gray-800 z-[2000] left-[50%] top-[2%] max-h-[400px] overflow-y-scroll addorderSearch">
              <div className="relative w-full p-4 bg-gray-600flex items-center justify-center">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="text-base outline-none text-gray-600 p-2 border rounded border-gray-300 focus:border-gray-300 w-[300px]"
                  placeholder="Serach user by email..."
                />
                <button
                  className="absolute z-[10000] -top-[2%] left-[91%] flex items-center justify-center p-2 rounded-full bg-blue-800 text-white"
                  onClick={handleCancelOrder}
                >
                  <IoMdClose className="text-white text-xl cursor-pointer" />
                </button>
              </div>
              <div className="flex flex-col items-center mt-4">
                {users
                  ?.filter((user) =>
                    user?.email
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  )
                  ?.map((user) => (
                    <div
                      key={user?._id}
                      className="flex items-center justify-between text-base  p-2 rounde shadow-xl w-[300px] mb-2"
                    >
                      <div className="flex flex-col items-start gap-1">
                        <span>
                          {user?.lastname} {user?.firstname}
                        </span>
                        <span>{user?.email}</span>
                      </div>
                      <button
                        className="outline-none border-none text-blue-600"
                        onClick={() => handleDisplayAddingOrder(user?._id)}
                      >
                        Ajouter
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col cursor-pointer">
          <select
            name="month"
            id="month"
            className="outline-none w-72 p-1 cursor-pointer bg-green-500 text-white rounded-tr rounded-br"
            value={month}
            onChange={handleMonthSelect}
          >
            <option value="Select month">Select month</option>
            {monthSelected?.map((wmonth) => (
              <option key={wmonth?.id} value={wmonth?.val}>
                {wmonth?.monthly}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="table_responsive">
        <table>
          <thead>
            <tr>
              <th>N° Order</th>
              <th>Product</th>
              {/* <th>Serveur</th> */}
              {/* <th>Qte</th> */}
              <th>PU</th>
              <th>Total</th>
              {/* {user?.person?.isAdmin && <th>Coordonnées de paiement</th>} */}
              {/* <th>Date</th> */}
              {/* <th>Date de paie</th> */}
              <th>Payment Information</th>
              <th>Status</th>
              {/* <th>Telephone</th>
              <th>Nom de jeu</th> */}
              {/* <th>Prenom et Nom</th> */}
              {/* <th>Méth de paie</th> */}
              {/* <th>Devise</th>
              <th>Meth de paie</th>
              <th>Banque de paie</th>
              <th>Rib</th>
              <th>Prénom Virement</th>
              <th>Nom Virement</th>
              <th>Prénom Paylib</th>
              <th>Nom Paylib</th>
              <th>Numéro Paylib</th>
              <th>Compte Payeer</th>
              <th>IBAN</th>
              <th>Email de paie</th> */}
              <th
                style={{
                  display:
                    user?.person?.email === "test@ibendoumatest.com"
                      ? "none"
                      : "flex",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {orderAll
              ?.filter(
                (order) => beginFilter === "" || order.status === beginFilter
              )
              ?.sort((a, b) => b.createdAt - a.createdAt)
              .map((order) => (
                <tr key={order?.id}>
                  <td
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontWeight: 700,
                    }}
                  >
                    <p className="order-sales">{order.numBuy}</p>
                    <p>{convertDate(order?.createdAt)}</p>
                  </td>
                  <td
                    className={
                      order?.jeu === "dofus-kamas"
                        ? "dofuskamas"
                        : order?.jeu === "dofus-touch"
                        ? "dofustouch"
                        : "dofusretro"
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "80px",
                      }}
                    >
                      <p>
                        {order?.jeu}-{order?.server}
                      </p>
                      <p>Name: {order?.gameName}</p>
                      <p>Quantity: {order?.qte}M</p>
                    </div>
                  </td>
                  {/* <td>{order?.server}</td> */}
                  {/* <td>{order?.qte}</td> */}
                  <td>{order?.pu}</td>
                  <td>{order?.totalPrice}</td>

                  <td
                    style={{
                      width: "300px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "2px",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {order?.paymentInfoDetails?.split("<br/>")[0]}:{" "}
                          {order?.paymentInfoDetails?.split("<br/>")[1]}
                        </p>
                      </div>
                      <p
                        style={{
                          display: "flex",
                          fontWeight: 600,
                        }}
                      >
                        Fullname:{" "}
                        {`${order?.detailUser?.lastname} ${order?.detailUser?.firstname}`}
                      </p>

                      <p
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        phone: {order?.detailUser?.phone}
                      </p>
                    </div>
                  </td>

                  {order?._id === editableId ? (
                    <td>
                      {" "}
                      <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="En attente">Pending</option>
                        <option value="En Cours de payment">In payment</option>
                        <option value="Payée">Payed</option>
                        <option value="Annulée">Canceled</option>
                      </select>{" "}
                    </td>
                  ) : (
                    <td
                      className={
                        order?.status === "Payée"
                          ? "success"
                          : order?.status === "Annulée"
                          ? "no-success"
                          : order?.status === "En Cours de payment"
                          ? "payment-success"
                          : order?.status === "En attente"
                          ? "pending"
                          : "no-pending"
                      }
                    >
                      {order?.status === "En attente" && "Pending"}
                      {order?.status === "En Cours de payment" && "In payment"}
                      {order?.status === "Payée" && "Payed"}
                      {order?.status === "Annulée" && "Canceled"}
                    </td>
                  )}

                  {order?._id === editableId ? (
                    <td
                      style={{
                        display:
                          user?.person?.email === "test@ibendoumatest.com"
                            ? "none"
                            : "flex",
                        marginTop: "15px",
                      }}
                    >
                      <div className="action_btn">
                        <button
                          className="servers-valid"
                          onClick={() => handleUpdateOrders(order?._id)}
                        >
                          Valider
                        </button>
                        <button
                          className="servers-cancel"
                          onClick={handleCancleModifOrders}
                        >
                          Annuler
                        </button>
                      </div>
                    </td>
                  ) : (
                    <td
                      style={{
                        display:
                          user?.person?.email === "test@ibendoumatest.com"
                            ? "none"
                            : "flex",
                        marginTop: "15px",
                      }}
                    >
                      <div className="action_btn">
                        <span
                          className="servers-delete"
                          onClick={() => handleDeleteOrder(order?._id)}
                        >
                          <RiDeleteBin6Line />
                        </span>
                        <span
                          className="servers-edit"
                          onClick={() => handleEditOrder(order?._id)}
                        >
                          <RiFileEditLine />
                        </span>
                        <span
                          className="text-[22px] ml-2 text-blue-600 cursor-pointer transition duration-300 ease-in-out hover:scale-110"
                          // onClick={() => closePopupiben(order?.orderNum, true)}
                          onClick={() => handleDisplayFacture(order?._id)}
                        >
                          <RiEyeFill />
                        </span>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
        {orderFactureToggle && (
          <div
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
            onClick={handleCancelInvoiceToggle}
          >
            <div
              className="flex flex-col bg-white items-startbg-white pl-8 pr-8 pt-4 pb-4 rounded shadow-lg w-[700px] h-[848px]"
              ref={targetRef}
            >
              <div className="w-full h-[10px] bg-blue-800"></div>
              <div className="w-full flex items-center justify-between">
                <img
                  src={ibendoumalogo}
                  alt="logo_ibendouma"
                  className="w-[140px] h-[140px] object-cover object-center"
                />
                <div className="flex flex-col items-start ml-12 mt-6 mb-4">
                  <h2 className="text-[26px] font-bold text-yellow-600">
                    iBendouma LTD
                  </h2>
                  <p className="text-sm max-w-[200px]">
                    71-75 Shelton Street, Covent Garden, Londres, Royaume Uni,
                    WC2H 9JQ
                  </p>
                  <p className="text-sm mt-1">(+44) 1273 90 1262</p>
                </div>
              </div>
              <h2 className="ml-12 mb-4 -mt-2 text-[40px] font-extrabold text-blue-900">
                Bon de commande
              </h2>
              <div className="flex items-start gap-16 ml-12 border-b pb-6 border-gray-300">
                <div className="flex flex-col items-start gap-1">
                  <p className="text-base font-bold">Date</p>
                  <p className="text-sm">
                    {convertDateOnly(orderInvoice?.createdAt)}
                  </p>
                </div>
                <div className="flex flex-col items-start gap-1">
                  <p className="text-base font-bold">Facture</p>
                  <p className="text-sm">I{orderInvoice?.numBuy}</p>
                </div>
                <div className="flex flex-col items-start gap-1">
                  <p className="text-base font-bold">N° de bon</p>
                  <p className="text-sm">{orderInvoice?.numBuy}</p>
                </div>
              </div>
              <div className="flex items-start gap-16 ml-12 pt-6 border-b pb-6 border-gray-300">
                <div className="flex flex-col items-start gap-1">
                  <p className="text-base font-bold">Fournisseur</p>
                  <p className="text-base font-bold">{`${orderInvoice?.lastname} ${orderInvoice?.firstname}`}</p>
                  <p className="text-sm max-w-[250px]">
                    {`${orderInvoice?.detailUser?.city}, ${orderInvoice?.detailUser?.address} ${orderInvoice?.detailUser?.country}`}
                  </p>
                  <p className="text-sm">{orderInvoice?.detailUser?.phone}</p>
                  <p className="text-sm">{orderInvoice?.detailUser?.email}</p>
                </div>
                <div className="flex flex-col items-start gap-1">
                  <p className="text-base font-bold">Destinataire</p>
                  <p className="text-base font-bold">Bendouma Ilyass</p>
                  <p>iBendouma Limited</p>
                  <p className="text-sm max-w-[250px]">
                    71-75 Shelton Street, Covent Garden, Londres, Royaume Uni,
                    WC2H 9JQ
                  </p>
                  <p className="text-sm">(+44) 1273 90 1262</p>
                  <p className="text-sm">info@ibendouma.com</p>
                </div>
              </div>
              <div className="flex flex-col items-start ml-12 pt-6">
                <div className="flex items-center mx-2 mb-2">
                  <span className="uppercase font-bold text-blue-700 text-sm">
                    Description des produits
                  </span>
                  <span className="uppercase font-bold text-blue-700 text-sm ml-14">
                    qte
                  </span>
                  <span className="uppercase font-bold text-blue-700 text-sm ml-16">
                    pu
                  </span>
                  <span className="uppercase font-bold text-blue-700 text-sm ml-28">
                    sous total (mad)
                  </span>
                </div>
                <div className="w-full border border-black"></div>
                <div className="flex items-center mx-2">
                  <span className="uppercase">
                    {`${orderInvoice?.jeu} ${orderInvoice?.server}`}
                  </span>
                  <span
                    className={`uppercase  ${
                      orderInvoice?.server?.length <= 8 ? "ml-16" : "ml-8"
                    }`}
                  >
                    {orderInvoice?.qte}M
                  </span>
                  <span
                    className={`uppercase ${
                      orderInvoice?.server?.length <= 8 ? "ml-16" : "ml-14"
                    }`}
                  >
                    {orderInvoice?.pu}
                  </span>
                  <span
                    className={`uppercase ${
                      orderInvoice?.server?.length <= 8 ? "ml-28" : "ml-32"
                    }`}
                  >
                    {recupBonus(orderInvoice?.totalPrice)}mad
                  </span>
                </div>
                <div className="mt-8 w-full border-b border-gray-300"></div>
              </div>
              <div className="w-full flex flex-col gap-4 items-end justify-end mt-1">
                <div className="flex items-center gap-10">
                  <span className="text-sm text-blue-600 font-bold">
                    Sous-total
                  </span>
                  <span className="text-sm font-bold uppercase">
                    {recupBonus(orderInvoice?.totalPrice)}
                    mad
                  </span>
                </div>
                <div className="flex items-center gap-16">
                  <span className="flex items-center text-sm text-blue-600 font-bold">
                    Bonus<span className="invisible">totale</span>
                  </span>
                  <span className="text-sm font-bold">
                    {getBonus(orderInvoice?.totalPrice)}
                  </span>
                </div>
                <p className="text-xl font-extrabold text-yellow-600 mr-6 uppercase">
                  {orderInvoice?.totalPrice}
                  mad
                </p>
              </div>
              <div className="flex items-center justify-between ml-12 mt-4">
                <button
                  className="text-sm p-2 rounded text-blue-600 font-semibold shadow-inner transition duration-500 ease-in-out hover:bg-blue-700 hover:text-white"
                  onClick={() => toPDF()}
                >
                  Télécharger en PDF
                </button>
                <img
                  src={cachet}
                  alt="cachet_ibendouma"
                  className="flex items-end justify-end w-[50px] h-[50px] object-cover rounded"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
export default Orders;
