/* @ts-nocheck */
/* eslint-disable */

import { configureStore, combineReducers } from "@reduxjs/toolkit";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import storage from "redux-persist/lib/storage";

// Ibytrade features imports
import userReducer from "../features/userSlices";
import serversReducer from "../features/serverSlices";
import euroRateReducer from "../features/rateEuroSlice";
import dollarRateReducer from "../features/rateDollarSlices";
import usdtRaChangeReducer from "../features/rateUsdtSlices";
import exchangeReducer from "../features/ExchangesSlice";
import rateReducer from "../features/rateSlice";
import soldeReducer from "../features/soldesSlices";
import soldeHistoryReducer from "../features/dataSoldeSlice";
import orderReducer from "../features/ordersSlice";
import notifReducer from "../features/notifAdd";

// Ibendouma features import
import useribenReducer from "../features/ibendouma/useribenSlices";
import serversibenReducer from "../features/ibendouma/serveribenSlices";
import euroRateibenReducer from "../features/ibendouma/rateEuroibenSlice";
import usdtRaChangeibenReducer from "../features/ibendouma/rateibenUsdtSlices";
import dollarRateibenReducer from "../features/ibendouma/rateDollaribenSlices";
import cadibenReducer from "../features/ibendouma/rateCadibenSlice";
import chfibenReducer from "../features/ibendouma/rateChfibenSlice";
import rubibenReducer from "../features/ibendouma/rateRubibenSlice";
import orderListibenReducer from "../features/ibendouma/orderibenSlice";
import rateibenReducer from "../features/ibendouma/rateibenSlice";
import soldeibenReducer from "../features/ibendouma/soldesibenSlices";
import soldeHistoryibenReducer from "../features/ibendouma/dataSoldeibenSlice";
import ordersibenReducer from "../features/ibendouma/ordersibenSlice";
import gbpibenReducer from "../features/ibendouma/rateGbpibenSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducers = combineReducers({
  // Ibytrade reducers imports
  user: userReducer,
  servers: serversReducer,
  eurorate: euroRateReducer,
  usdtra: usdtRaChangeReducer,
  dollarateiben: dollarRateibenReducer,
  exchange: exchangeReducer,
  rate: rateReducer,
  solde: soldeReducer,
  soldes: soldeHistoryReducer,
  orders: orderReducer,
  dataNotif: notifReducer,

  // Ibendouma reducers import
  useriben: useribenReducer,
  serversiben: serversibenReducer,
  eurorateiben: euroRateibenReducer,
  usdtraiben: usdtRaChangeibenReducer,
  dollarate: dollarRateReducer,
  orderlistiben: orderListibenReducer,
  rateiben: rateibenReducer,
  soldeiben: soldeibenReducer,
  soldesiben: soldeHistoryibenReducer,
  ordersiben: ordersibenReducer,
  cadrateiben: cadibenReducer,
  chfrateiben: chfibenReducer,
  rubrateiben: rubibenReducer,
  gbprateiben: gbpibenReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV === "development",
});

export let persistor = persistStore(store);
