/* @ts-nocheck */
/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Header } from "../components";

import axios from "axios";
import { useSelector } from "react-redux";

import { BiSearch } from "react-icons/bi";

const Worker = () => {
  const { user } = useSelector((state) => state.user);
  const [workersInfos, setWorkersInfos] = useState([]);
  const [workersChange, setWorkersChange] = useState(workersInfos);
  const [searchTerm, setSearchTerm] = useState("");
  const [clientId, setClientId] = useState("654cb36b21b10f0db0ca4965");

  console.log(workersInfos);
  console.log(user);

  const convertDateAndHours = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    return dateConverted;
  };

  const convertDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      weekday: "long",
    });
    return dateConverted;
  };

  useEffect(() => {
    const getWorkers = async () => {
      await axios
        .get(`${process.env.REACT_APP_CLIENT_URL}/users/workers/allworkers/`)
        .then((res) => setWorkersInfos(res?.data))
        .catch((error) => console.log(error));
    };
    getWorkers();
  }, []);

  const getIpToConnect = (ip) => {
    if (!ip) return "Not Found";
    let ipReceive = ip.split(":")[3];
    return ipReceive;
  };

  const convertHoursAndMinutes = (val) => {
    let numberHoursAdd = val + 60;
    let numberHours = numberHoursAdd / 60;
    let heurs = Math.trunc(numberHours);
    let dec = numberHours - heurs;
    let minuts = Math.floor(dec * 60);

    return `${heurs > 0 ? `${heurs}H` : ""}${heurs > 0 && minuts ? ":" : ""}${
      minuts > 0 ? `${minuts}${heurs <= 0 ? "Min" : ""}` : ""
    }`;
  };

  const calculateSalary = (salEmail, delay) => {
    let sal = 0;
    if (salEmail === "hibazinaoui@gmail.com") {
      sal = 3200;
      let salperDay = Math.floor(sal / 26);
      let delayAdd = delay + 60;
      let delayPerDay = delayAdd / 60;
      if (delayPerDay >= 4) {
        let calcDelatePerDay = Math.floor(delayPerDay / 4);
        let lostPerDay = calcDelatePerDay * salperDay;
        let salary = sal - lostPerDay;
        return salary.toFixed(2);
      } else {
        return sal;
      }
    } else if (salEmail === "ayoub@gmail.com") {
      sal = 3200;
      let salperDay = Math.floor(sal / 26);
      let delayAdd = delay + 60;
      let delayPerDay = delayAdd / 60;
      if (delayPerDay >= 4) {
        let calcDelatePerDay = Math.floor(delayPerDay / 4);
        let lostPerDay = calcDelatePerDay * salperDay;
        let salary = sal - lostPerDay;
        return salary.toFixed(2);
      } else {
        return sal;
      }
    } else if (salEmail === "soukaina@gmail.com") {
      sal = 2000;
      let salperDay = Math.floor(sal / 26);
      let delayAdd = delay + 60;
      let delayPerDay = delayAdd / 60;
      if (delayPerDay >= 4) {
        let calcDelatePerDay = Math.floor(delayPerDay / 4);
        let lostPerDay = calcDelatePerDay * salperDay;
        let salary = sal - lostPerDay;
        return salary.toFixed(2);
      } else {
        return sal;
      }
    } else if (salEmail === "mamadousy@gmail.com") {
      sal = 4700;
      let salperDay = Math.floor(sal / 26);
      let delayAdd = delay + 60;
      let delayPerDay = delayAdd / 60;
      if (delayPerDay >= 4) {
        let calcDelatePerDay = Math.floor(delayPerDay / 4);
        let lostPerDay = calcDelatePerDay * salperDay;
        let salary = sal - lostPerDay;
        return salary.toFixed(2);
      } else {
        return sal;
      }
    } else {
      sal = 0;
    }
  };

  useEffect(() => {
    if (user?.user === clientId) {
      setWorkersChange(
        workersInfos?.filter((worker) => worker?.userId !== clientId)
      );
    } else {
      setWorkersChange(
        workersInfos?.filter((worker) => worker?.userId === user?.user)
      );
    }
  }, [clientId, workersInfos]);

  //   console.log(calculateSalary("hibazinaoui@gmail.com"));

  return (
    <div className="m-2 md:m-6 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Worker" title="Workers details" />
      <div className="w-full flex flex-col items-center justify-around">
        <div className="flex relative w-full">
          <input
            type="text"
            name="search"
            id="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Find a worker..."
            className="bg-gray-300 text-lg p-2 w-full rounded outline-none"
          />
          <span className="absolute text-[26px] left-[96%] top-[50%] -translate-y-1/2 text-gray-500">
            <BiSearch />
          </span>
        </div>
        <table className="w-full text-center border-collapse">
          <thead className="text-lg font-normal">
            <tr className="px-2 py-4">
              <th className="border border-gray-700">Prenom</th>
              <th className="border border-gray-700">Nom</th>
              <th className="border border-gray-700">Connexion</th>
              <th className="border border-gray-700">Déconnexion</th>
              <th className="border border-gray-700">Retards</th>
              <th className="border border-gray-700">Salaire</th>
              <th className="border border-gray-700">Ip</th>
              <th className="border border-gray-700">Jour</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 font-normal text-lg">
            {workersChange
              ?.filter((worker) =>
                worker?.lastname
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
              .map((worker, index) => (
                <tr
                  key={worker?._id}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="border border-gray-700">{worker?.lastname}</td>
                  <td className="border border-gray-700">
                    {worker?.firstname}
                  </td>
                  <td className="border border-gray-700">
                    {convertDateAndHours(worker?.connect)}
                  </td>
                  <td className="border border-gray-700">
                    {convertDateAndHours(worker?.deconnect)}
                  </td>
                  <td className="border border-gray-700">
                    {convertHoursAndMinutes(worker?.delay)}
                  </td>
                  <td className="border border-gray-700 text-black font-bold">
                    {calculateSalary(worker?.email, worker?.delay)}dh
                  </td>
                  <td className="border border-gray-700">
                    {getIpToConnect(worker?.ip)}
                  </td>
                  <td className="border border-gray-700">
                    {convertDate(worker?.date)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Worker;
