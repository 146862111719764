/* @ts-nocheck */
/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../components";

import { RiDeleteBin6Line } from "react-icons/ri";
import { RiFileEditLine } from "react-icons/ri";

import { getExchange } from "../features/ExchangesSlice";

import { BiSearch } from "react-icons/bi";

import { updateExchange, deleteExchange } from "../features/ExchangesSlice";

import { updateRate } from "../features/rateSlice";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BsSlashLg } from "react-icons/bs";
import { BsCheckLg, BsFillCheckCircleFill, BsPlus } from "react-icons/bs";
import { MdCancel, MdOutlinePendingActions } from "react-icons/md";
import { FaRegListAlt, FaCloudDownloadAlt } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { FiTruck } from "react-icons/fi";

const Echanges = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.exchange);
  const { user } = useSelector((state) => state.user);
  const { servers } = useSelector((state) => state.servers);
  const { echangeId } = useParams();
  const [allProducts, setAllProducts] = useState(products);
  const [searchTerm, setSearchTerm] = useState("");
  // console.log(allProducts);
  // console.log(echangeId);
  // console.log(servers);

  // const fixedValue = (valToFixed) =>{}

  const viewCat = (valToView) => {
    let valueToRe = servers.filter(
      (server) => server?.serverName === valToView
    )[0]?.serverCategory;
    return valueToRe;
  };

  const { rate } = useSelector((state) => state.rate);
  const { rateId } = useSelector((state) => state.rate);

  const [toggleEdit, setToggleEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [status, setStatus] = useState("");
  const [rateUpdated, setRateUpdated] = useState(rate);
  const [editRate, setEditRate] = useState(false);


  const Status = [
    {
      bg: "bg-orange-200",
      icon: FaRegListAlt,
      color: "text-star",
      title: "Orders",
      total: 12,
    },
    {
      bg: "bg-yellow-200",
      icon: MdOutlinePendingActions,
      color: "text-star",
      title: "In progress",
      total: 20,
    },
    {
      bg: "bg-green-200",
      icon: BsFillCheckCircleFill,
      color: "text-main",
      title: "Completed",
      total: 18,
    },
    {
      bg: "bg-red-200",
      icon: ImCancelCircle,
      color: "text-flash",
      title: "Canceled",
      total: 30,
    },
  ];

  const notifySuccessToUpadteExchange = (name) =>
    toast.success("Exchange number " + name + " successfully updated");

  const notifySuccessToDeleteExchange = (name) =>
    toast.success("Exchange number " + name + " successfully deleted");

  const notifySuccessToUpdateRate = () =>
    toast.success("Exchange rate updated successfully");

  const convertDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    return dateConverted;
  };

  // console.log(products);

  const handleToggleEdit = (idToEdit) => {
    setEditId(idToEdit);
  };

  const cancelModif = () => {
    setEditId("");
  };

  const handleEditRate = () => {
    setEditRate(true);
  };

  const cancelModifRate = () => {
    setEditRate(false);
  };

  useEffect(() => {
    const getExchanges = async () => {
      await axios
        .get(`${process.env.REACT_APP_CLIENT_URL}/exchange`)
        .then((res) => {
          dispatch(getExchange(res.data));
        });
    };
    getExchanges();
  }, [dispatch]);

  useEffect(() => {
    if (echangeId) {
      setAllProducts(
        products?.filter((exchange) =>
          exchange?.numExchange?.includes(echangeId)
        )
      );
    } else {
      setAllProducts(products);
    }
  }, [echangeId, products]);

  const handleUpdateExchange = (idToUpdate) => {
    try {
      axios({
        method: "put",
        url: `${process.env.REACT_APP_CLIENT_URL}/exchange/${idToUpdate}`,
        data: {
          status,
        },
      }).then((res) => {
        dispatch(updateExchange(res?.data));
        notifySuccessToUpadteExchange(res?.data?.numExchange);
        setEditId(null);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveExchange = (idToDelete) => {
    try {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_CLIENT_URL}/exchange/${idToDelete}`,
      }).then((res) => {
        dispatch(deleteExchange({ id: res?.data?._id }));
        notifySuccessToDeleteExchange(res?.data?.numExchange);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateRate = () => {
    try {
      axios({
        method: "put",
        url: `${process.env.REACT_APP_CLIENT_URL}/order/rate/${rateId}`,
        data: {
          rate: rateUpdated,
        },
      }).then((res) => {
        dispatch(updateRate({ rateUpdated: res?.data?.rate }));
        notifySuccessToUpdateRate();
        setEditRate(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterOrder = (titleOrderToFilter) => {
    if (titleOrderToFilter === "In progress") {
      setAllProducts(
        products?.filter((product) => product?.status === "En attente")
      );
    } else if (titleOrderToFilter === "Completed") {
      setAllProducts(
        products?.filter((product) => product?.status === "Terminée")
      );
    } else if (titleOrderToFilter === "Canceled") {
      setAllProducts(
        products?.filter((product) => product?.status === "Annulée")
      );
    } else if (titleOrderToFilter === "Orders") {
      setAllProducts(products);
    } else {
      setOrderAll(products);
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg echanges">
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-2 bg-white rounded mx-2">
        {Status.map((status, i) => (
          <div
            key={i}
            className="p-2 rounded grid grid-cols-4 gap-2 cursor-pointer"
            onClick={() => handleFilterOrder(status?.title)}
          >
            <div
              className={`rounded-full p-4 flex items-center justify-center col-span-1 text-lg ${status.bg} ${status.color}`}
            >
              <status.icon />
            </div>
            <div className="flex flex-col items-center col-span-3">
              <h2>{status.title}</h2>
              <p className="font-bold mt-2">{status.total}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-end justify-end mr-4 mt-8">
        {/* <Header category="Dofus" title="Echanges" /> */}
        <div className="infos-user-search">
          <input
            type="text"
            name="search"
            id="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="N° Order"
          />
          <span className="search_addnewsolde">
            <BiSearch />
          </span>
        </div>
        <div className="flex items-center gap-6 rate-container">
          <div className="rate_update">
            <span className="server-rate-text">Exchange rate</span>
            {editRate ? (
              <input
                className="input-rate"
                type="number"
                value={rateUpdated}
                onChange={(e) => setRateUpdated(e.target.value)}
              />
            ) : (
              <span className="server-rate-Value">{rateUpdated}</span>
            )}
          </div>
          {editRate ? (
            <div
              className="action_btn"
              style={{
                marginTop: "20px",
              }}
            >
              <button className="servers-valid" onClick={handleUpdateRate}>
                Confirm
              </button>
              <button className="servers-cancel" onClick={cancelModifRate}>
                Cancel
              </button>
            </div>
          ) : (
            <div
              className="echanges-edit-btn"
              onClick={handleEditRate}
              style={{
                display:
                  user?.person?.email === "test@ibendoumatest.com"
                    ? "none"
                    : "flex",
              }}
            >
              <span className="rate-editButton">
                <RiFileEditLine />
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="table_responsive">
        <table>
          <thead>
            <tr>
              <th>N° Order</th>
              <th>Code</th>
              <th>Server send</th>
              {/* <th>Qté à échanger</th>
              <th>Nom perso</th> */}
              <th>Server recerved</th>
              {/* <th>Qté à rec</th>
              <th>Nom perso</th> */}
              {/* <th>Date</th> */}
              <th>Status</th>
              <th>Email/TP</th>
              <th
                style={{
                  display:
                    user?.person?.email === "test@ibendoumatest.com"
                      ? "none"
                      : "flex",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {allProducts
              ?.filter((product) => product.numExchange.includes(searchTerm))
              ?.map((product) => (
                <tr key={product?._id}>
                  <td>
                    <div className="flex flex-col items-start font-semibold">
                      <p>{product.numExchange}</p>
                      <p>{convertDate(product?.createdAt)}</p>
                    </div>
                  </td>
                  <td>{product.codeToExchange}</td>
                  <td>
                    <div className="flex flex-col items-start">
                      <p>
                        {viewCat(product?.serverOut)} - {product?.serverOut}
                      </p>
                      <p>Name: {product?.characterToPay}</p>
                      <p>Quantity: {product.qtyToPay}M</p>
                    </div>
                  </td>
                  {/* <td>{product.qtyToPay}</td>
                  <td>{product.characterToPay}</td> */}
                  {/* <td className="flex flex-col items-start">
                    <p>
                      {viewCat(product?.serverIn)} - {product?.serverIn}
                    </p>
                    <p>Name: {product?.characterToReceive}</p>
                    <p>Quantity: {product?.qtyToReceive}M</p>
                  </td> */}
                  <td>
                    <div className="flex flex-col items-end">
                      <p>
                        {viewCat(product?.serverIn)} - {product?.serverIn}
                      </p>
                      <p>Name: {product?.characterToReceive}</p>
                      <p>Quantity: {product?.qtyToReceive}M</p>
                    </div>
                  </td>
                  {/* <td>{product.qtyToReceive}</td>
                  <td>{product.characterToReceive}</td> */}
                  {/* <td>{convertDate(product.createdAt)}</td> */}
                  {editId === product?._id ? (
                    <select
                      className="echanges_selec"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="En attente">In progress</option>
                      <option value="Terminée">Completed</option>
                      <option value="Annulée">Canceled</option>
                    </select>
                  ) : (
                    <td
                      className={
                        product.status === "Terminée"
                          ? "success"
                          : product.status === "Annulée"
                          ? "no-success"
                          : product.status === "En attente"
                          ? "pending"
                          : "no-pending"
                      }
                    >
                      {product.status === "En attente" && "In progress"}
                      {product.status === "Terminée" && "Completed"}
                      {product.status === "Annulée" && "Canceled"}
                    </td>
                  )}
                  <td>
                    <p className="flex items-center">
                      {product?.detailUser?.email}
                      <BsSlashLg className="text-base font-semibold" />
                      {product?.detailUser?.phone}
                    </p>
                  </td>
                  <td
                    style={{
                      display:
                        user?.person?.email === "test@ibendoumatest.com"
                          ? "none"
                          : "flex",
                    }}
                  >
                    {editId === product?._id ? (
                      <div className="action_btn">
                        <button
                          className="servers-valid"
                          onClick={() => handleUpdateExchange(product?._id)}
                        >
                          Confirm
                        </button>
                        <button
                          className="servers-cancel"
                          onClick={cancelModif}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <div>
                        <span
                          className="servers-delete"
                          onClick={() => handleRemoveExchange(product?._id)}
                        >
                          <RiDeleteBin6Line />
                        </span>
                        <span
                          className="servers-edit"
                          onClick={() => handleToggleEdit(product._id)}
                        >
                          <RiFileEditLine />
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Echanges;
