/* @ts-nocheck */
/* eslint-disable */

import React from "react";

const OrderHistory = ({ toggleHistoryUpdated, setToggleHistoryUpdated }) => {
  return (
    toggleHistoryUpdated && (
      <div
        className="orderhistory"
        onClick={() => setToggleHistoryUpdated(false)}
      ></div>
    )
  );
};

export default OrderHistory;
