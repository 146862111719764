/* @ts-nocheck */
/* eslint-disable */

import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";

import { Sidebar, ThemeSettings } from "./components";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { Orders, Employees } from "./pages";
import "./App.css";

import { useStateContext } from "./contexts/ContextProvider";
import SignIn from "./pages/SignIn";
import Servers from "./pages/Servers";
import AddNewServer from "./components/AddNewServer";
import Clients from "./pages/Clients";
import ProfilDetails from "./pages/ProfilDetails";
import Echanges from "./pages/Echanges";
import Balances from "./pages/Balances";
import AddNewSolde from "./components/AddNewSolde";
import AddNewDofusOrder from "./components/AddNewDofusOrder";
import SendSms from "./components/SendSms";
import BackDrop from "./components/BackDrop";
import OrderHistory from "./components/OrderHistory";
import Loader from "./components/Loader";
import IbendoumaServers from "./pages/IbendoumaServers";
import IbendoumaOrders from "./pages/IbendoumaOrders";
import IbendoumaCustomer from "./pages/IbendoumaCustomer";
import BackdropIben from "./components/BackdropIben";
import PaypalDrop from "./components/PaypalDrop";
import PaypalDrawer from "./components/PaypalDrawer";
import { getAllUsers } from "./features/ibendouma/useribenSlices";
import Worker from "./pages/Worker";
import AddIbendoumaNewServer from "./components/AddIbendoumaNewServer";

const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [userId, setUserId] = useState(user?.user);
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  const [showPopup, setShowPopup] = useState(false);
  const [toggleHistoryUpdated, setToggleHistoryUpdated] = useState(false);
  const [info, setInfo] = useState({});
  const [idPopupUser, setIdPopupUser] = useState("");
  const [isViewProduct, setIsViewProduct] = useState(false);
  const [isViewUserId, setIsViewUserId] = useState("");

  const [showPopupiben, setShowPopupiben] = useState(false);
  const [infoiben, setInfoiben] = useState({});
  const [idPopupUseriben, setIdPopupUseriben] = useState("");
  const [paypalInfoToggle, setPaypalInfoToggle] = useState(false);
  const [paypalInfo, setPaypalInfo] = useState({});

  useEffect(() => {
    const getUsers = async () => {
      await axios
        .get(`${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/users`)
        .then((res) => dispatch(getAllUsers(res.data)));
    };
    getUsers();
  }, [dispatch]);

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  const closePopup = (id, state) => {
    if (id && state) {
      setIdPopupUser(id);
      setShowPopup(state);
    } else {
      setShowPopup(false);
    }
  };

  const closePopupiben = (id, state) => {
    if (id && state) {
      setIdPopupUseriben(id);
      setShowPopupiben(state);
    } else {
      setShowPopupiben(false);
    }
  };

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_IBENDOUMA_CLIENT_URL}/order/ordernum/${idPopupUseriben}`,
      }).then((res) => {
        setInfoiben(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, [idPopupUseriben]);

  const getPaypalInfo = (item) => {
    setPaypalInfo(item);
  };

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_CLIENT_URL}/users/${idPopupUser}`,
      }).then((res) => {
        setInfo(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, [idPopupUser]);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            {user && user?.person?.isAdmin && (
              <TooltipComponent content="Paramètres" position="Top">
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: "50%" }}
                  className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            )}
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
              {user && user?.person?.isAdmin && <Sidebar />}
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              {user && user?.person?.isAdmin && <Sidebar />}
            </div>
          )}
          <div
            className={
              activeMenu
                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full"
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2"
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
              {user && user?.person?.isAdmin && <Navbar />}
              <BackDrop showPopup={showPopup} closePopup={closePopup} />
              <OrderHistory
                toggleHistoryUpdated={toggleHistoryUpdated}
                setToggleHistoryUpdated={setToggleHistoryUpdated}
              />
              <Loader
                isViewProduct={isViewProduct}
                setIsViewProduct={setIsViewProduct}
              />
              <AddNewDofusOrder
                isViewProduct={isViewProduct}
                isViewUserId={isViewUserId}
              />
              <BackdropIben
                showPopupiben={showPopupiben}
                closePopupiben={closePopupiben}
              />
              <PaypalDrawer
                paypalInfoToggle={paypalInfoToggle}
                paypalInfo={paypalInfo}
              />
              <PaypalDrop
                paypalInfoToggle={paypalInfoToggle}
                paypalClick={() => setPaypalInfoToggle(false)}
              />
            </div>
            <div>
              {themeSettings && <ThemeSettings />}

              <Routes>
                {/* dashboard  */}
                <Route
                  path="/"
                  element={
                    user && user?.person?.isAdmin ? <Servers /> : <SignIn />
                  }
                />
                <Route path="/signin" element={<SignIn />} />

                {/* pages  */}

                <Route
                  path="/servers"
                  element={
                    user && user?.person?.isAdmin ? <Servers /> : <SignIn />
                  }
                />

                <Route
                  path="/send-sms"
                  element={
                    user && user?.person?.isAdmin ? <SendSms /> : <SignIn />
                  }
                />

                <Route
                  path="/addnewserver"
                  element={
                    user && user?.person?.isAdmin ? (
                      <AddNewServer />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/addnewibendoumaserver"
                  element={
                    user && user?.person?.isAdmin ? (
                      <AddIbendoumaNewServer />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/ibendouma/servers"
                  element={
                    user && user?.person?.isAdmin ? (
                      <IbendoumaServers />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/ibendouma/commandes"
                  element={
                    user && user?.person?.isAdmin ? (
                      <IbendoumaOrders
                        showPopupiben={showPopupiben}
                        closePopupiben={closePopupiben}
                        infoiben={infoiben}
                        getPaypalInfo={getPaypalInfo}
                        setPaypalInfoToggle={setPaypalInfoToggle}
                        toggleHistoryUpdated={toggleHistoryUpdated}
                        setToggleHistoryUpdated={setToggleHistoryUpdated}
                        setIsViewProduct={setIsViewProduct}
                        setIsViewUserId={setIsViewUserId}
                        isViewProduct={isViewProduct}
                      />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/ibendouma/clients"
                  element={
                    user && user?.person?.isAdmin ? (
                      <IbendoumaCustomer
                        showPopupiben={showPopupiben}
                        closePopupiben={closePopupiben}
                        infoiben={infoiben}
                      />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/gc"
                  element={
                    user && user?.person?.isAdmin ? <Employees /> : <SignIn />
                  }
                />

                <Route
                  path="/clients"
                  element={
                    user && user?.person?.isAdmin ? (
                      <Clients
                        showPopup={showPopup}
                        closePopup={closePopup}
                        info={info}
                      />
                    ) : (
                      <SignIn />
                    )
                  }
                />

                <Route
                  path="/soldes"
                  element={
                    user && user?.person?.isAdmin ? <Balances /> : <SignIn />
                  }
                />
                <Route
                  path="/workers"
                  element={
                    user && user?.person?.isAdmin ? <Worker /> : <SignIn />
                  }
                />

                <Route
                  path="/addnewsolde"
                  element={
                    user && user?.person?.isAdmin ? <AddNewSolde /> : <SignIn />
                  }
                />

                {/* apps  */}

                <Route
                  path="/profil/:profilId"
                  element={
                    user && user?.person?.isAdmin ? (
                      <ProfilDetails />
                    ) : (
                      <SignIn />
                    )
                  }
                />

                <Route
                  path="/echanges"
                  element={
                    user && user?.person?.isAdmin ? <Echanges /> : <SignIn />
                  }
                />

                <Route
                  path="/echanges/:echangeId"
                  element={
                    user && user?.person?.isAdmin ? <Echanges /> : <SignIn />
                  }
                />

                <Route
                  path="/commandes"
                  element={
                    user && user?.person?.isAdmin ? (
                      <Orders
                        toggleHistoryUpdated={toggleHistoryUpdated}
                        setToggleHistoryUpdated={setToggleHistoryUpdated}
                        setIsViewProduct={setIsViewProduct}
                        setIsViewUserId={setIsViewUserId}
                        isViewProduct={isViewProduct}
                      />
                    ) : (
                      <SignIn />
                    )
                  }
                />

                <Route
                  path="/commandes/:commandId"
                  element={
                    user && user?.person?.isAdmin ? <Orders /> : <SignIn />
                  }
                />

                {/* charts  */}
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
