/* @ts-nocheck */
/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  rateiben: 1,
  rateId: null,
};

export const rateibenSlice = createSlice({
  name: "rateiben",
  initialState,
  rateId: "",

  reducers: {
    getRate: (state, action) => {
      state.rateiben = action.payload.rate;
      state.rateId = action.payload.idRate;
      state.loading = false;
    },

    updateRate: (state, action) => {
      state.rateiben = action.payload.rateUpdated;
    },
  },
});

export const { getRate, updateRate } = rateibenSlice.actions;

export default rateibenSlice.reducer;
