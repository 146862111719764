/* @ts-nocheck */
/* eslint-disable */
import React from "react";

import "./styles.css";

const PaypalDrawer = ({ paypalInfoToggle, paypalInfo }) => {
  const sideDrawerClass = ["addrawer"];
  if (paypalInfoToggle) {
    sideDrawerClass.push("addshow");
  }

  const convertDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    return dateConverted;
  };

  return paypalInfoToggle && paypalInfo ? (
    <div className={sideDrawerClass.join(" ")}>
      <h1 className="text-lg font-semibold mt-2">
        Informations de paiement paypal
      </h1>
      <h1 className="flex flex-row items-center text-lg mb-4 font-semibold">
        Status de paiement:{" "}
        <span className="flex ml-2 text-xl text-green-700 font-bold">
          {paypalInfo?.status === "COMPLETED" && "Payé"}{" "}
        </span>
      </h1>
      <div className="flex flex-row items-start justify-between p-4 gap-8">
        <div className="flex flex-col items-start gap-2">
          <div className="flex item-start gap-2 items-center justify-center bg-blue-600 p-2 rounded text-white">
            <span>ID de paiement:</span>
            <span>{paypalInfo?.id} </span>
          </div>
          <div className="flex item-start gap-2 items-center justify-center bg-blue-600 p-2 rounded text-white">
            <span>Prenom:</span>
            <span>{paypalInfo?.payer?.name?.given_name} </span>
          </div>
          <div className="flex item-start gap-2 items-center justify-center bg-blue-600 p-2 rounded text-white">
            <span>Nom:</span>
            <span>{paypalInfo?.payer?.name?.surname} </span>
          </div>
          <div className="flex item-start gap-2 items-center justify-center bg-blue-600 p-2 rounded text-white">
            <span>Email:</span>
            <span>{paypalInfo?.payer?.email_address} </span>
          </div>
          <div className="flex item-start gap-2 items-center justify-center bg-blue-600 p-2 rounded text-white">
            <span>Telephone:</span>
            <span>
              {paypalInfo?.payer?.phone?.phone_number?.national_number}{" "}
            </span>
          </div>
          <div className="flex item-start gap-2 items-center justify-center bg-blue-600 p-2 rounded text-white">
            <span>Country Code :</span>
            <span>{paypalInfo?.payer?.address?.country_code} </span>
          </div>
        </div>

        <div className="flex flex-col items-start gap-2">
          <div className="flex item-start gap-2 items-center justify-center bg-blue-600 p-2 rounded text-white">
            <span>Devise:</span>
            <span>
              {
                paypalInfo?.purchase_units[0]?.payments?.captures[0]?.amount
                  ?.currency_code
              }{" "}
            </span>
          </div>
          <div className="flex item-start gap-2 items-center justify-center bg-blue-600 p-2 rounded text-white">
            <span>Valeur:</span>
            <span>
              {
                paypalInfo?.purchase_units[0]?.payments?.captures[0]?.amount
                  ?.value
              }{" "}
            </span>
          </div>
          <div className="flex item-start gap-2 items-center justify-center bg-blue-600 p-2 rounded text-white">
            <span>Date d'achat:</span>
            <span>
              {convertDate(
                paypalInfo?.purchase_units[0]?.payments?.captures[0]
                  ?.create_time
              )}{" "}
            </span>
          </div>
          <div className="flex item-start gap-2 items-center justify-center bg-blue-600 p-2 rounded text-white">
            <span>Adresse:</span>
            <span>
              {paypalInfo?.purchase_units[0]?.shipping?.address?.address_line_1}
            </span>
          </div>
          <div className="flex item-start gap-2 items-center justify-center bg-blue-600 p-2 rounded text-white">
            <span>Ville:</span>
            <span>
              {paypalInfo?.purchase_units[0]?.shipping?.address?.admin_area_2}
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={sideDrawerClass.join(" ")}>
      <h1 className="flex flex-row items-center text-lg font-semibold">
        Pas de paiement paypal sur cette commande
      </h1>
    </div>
  );
};

export default PaypalDrawer;
