/* @ts-nocheck */
/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  usdtraiben: 1,
  idusdt: "",
};

export const rateibenUsdtSlices = createSlice({
  name: "usdtraiben",
  initialState,
  reducers: {
    getUsdtRa: (state, action) => {
      state.usdtraiben = action.payload.usdt;
      state.idusdt = action.payload.idusdt;
      state.loading = false;
    },

    updateUsdt: (state, action) => {
      state.usdtraiben = action.payload.usdt;
    },
  },
});

export const { getUsdtRa, updateUsdt } = rateibenUsdtSlices.actions;

export default rateibenUsdtSlices.reducer;
