/* @ts-nocheck */
/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  ordersiben: null,
};

export const ordersibenSlice = createSlice({
  name: "ordersiben",
  initialState,

  reducers: {
    getOrders: (state, action) => {
      state.ordersiben = action.payload;
      state.loading = false;
    },
    updateOrder: (state, action) => {
      state.ordersiben = state.ordersiben.map((order) => {
        if (order._id === action.payload._id) {
          return {
            ...order,
            status: action.payload.status,
            capture: action.payload.capture,
          };
        } else {
          return order;
        }
      });
    },

    deleteOrder: (state, action) => {
      state.ordersiben = state.ordersiben.filter(
        (order) => order._id !== action.payload.id
      );
    },

    addNewOrder: (state, action) => {
      state.ordersiben.push(action.payload);
    },
  },
});

export const { getOrders, updateOrder, deleteOrder, addNewOrder } =
  ordersibenSlice.actions;

export default ordersibenSlice.reducer;
