/* @ts-nocheck */
/* eslint-disable */

import React from "react";

const Loader = ({ isViewProduct, setIsViewProduct }) => {
  return (
    isViewProduct && (
      <div
        className="fixed inset-0 flex items-center justify-center bg-black opacity-60 z-50"
        onClick={() => setIsViewProduct(false)}
      >
        <div className="flex flex-col items-center"></div>
      </div>
    )
  );
};

export default Loader;
